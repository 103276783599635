import React from 'react';
import {ReactComponent as Close} from '../../assets/icons/close.svg';
import './styles.scss';

const Notification = (
    {
        children,
        onClose,
        onChange,
        isShow = false,
        isEnd =false,
        style
    }
) => {
    // console.log(attrs);
    return isShow && (
        <div className="notification" style={{...style}}>
            <div className="notification__wrap">
                <div className="notification__close" onClick={onClose}>
                    <Close />
                </div>
                <div className="notification__content">
                    {children}
                </div>
                {!isEnd && (
                    <div className="notification__next" onClick={onChange}>
                        Следующая подсказка
                    </div>
                )}
            </div>
        </div>
    )
}

export default Notification;
