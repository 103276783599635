import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
// import TheFrame from '../../components/Frame'
// import Link from '../../components/Link'
// import Document from '../../components/Document/Detail'
import Layout from '../../../components/Layout';
import TitleEditable from './TitleEditable';
// import Alert from '../../components/Alert/Alert'
import './styles.scss'
// import {formatCertName} from "../../../../utils/format"
// import { TYPE_SIGNED, TYPE_UNSIGNED } from '../../components/Document/sign.types'
import {ReactComponent as KeyIcon} from "../../../assets/icons/key.svg";
import {ReactComponent as QuestionIcon} from "../../../assets/icons/question.svg";
// import {ReactComponent as CheckIcon} from "../../assets/icons/Check.svg";
import CheckIcon from "../../../assets/img/Path.png";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as ConfirmIcon} from "../../../assets/icons/confirm.svg";
import {ReactComponent as DoubleCheckIcon} from "../../../assets/icons/Unionpath.svg";
import {formatTime, formatDateDMY} from "../../../../../utils/date";
import Controls from "../../../components/Controls";
import {API_DOMAIN} from "../../../../../utils/const";
import {loadDocument, renameDocument} from "../../../../../store/actions";

const Detail = ({ documents, profile, dispatch }) => {
    const history = useHistory();
    const { id } = useParams();
    useEffect(() => {
        dispatch(loadDocument(id))
    }, [id])
    const {
        name,
        filename = '',
        is_signed_by_user,
        signed,
        signatures = [],
        public_id = '',
        url = 'https://beta.sign.legium.io/'
    } = documents[id]?.data || {};
    const [title, setTitle] = useState();
    const documentPath = new URL(url).pathname;
    const documentUrl = API_DOMAIN + documentPath;
    // const { signed } = document
    // const signType = signed ? TYPE_SIGNED : TYPE_UNSIGNED
    // const signLabel = signed ? 'Подписаны' : 'На подписании'
    const partner = profile && profile.partner
    const proxy = profile && profile.proxy

    let hasPermissionToSign = true
    if (partner === 'MRG') {
        if (proxy) {
            const proxyDate = new Date(proxy.proxy_data)
            const proxyDeadline = new Date(proxy.proxy_deadline)
            const currentDate = new Date()
            hasPermissionToSign = currentDate >= proxyDate && currentDate <= proxyDeadline
        } else {
            hasPermissionToSign = false
        }
    }
    const renameTitle = (name) => {
        setTitle(name);
        dispatch(renameDocument(id, name));
    }

    return (
        <Layout isFooterHidden={true}>
            <div className="detail">
                <div className="detail__bg" />
                <div className="detail__card">
                    <TitleEditable isSigned={is_signed_by_user} emitData={renameTitle}>
                        {title || name || filename}
                    </TitleEditable>
                    <div className="detail__content">
                        {signatures?.[0]?.inn && (
                            <div className="detail__content_signed">
                                <div className="detail__content_title">
                                    <img src={CheckIcon} alt=""/>&nbsp;
                                    Подписал
                                </div>
                                <div className="detail__content_block">
                                    <div className="detail__content_name">
                                        {formatTime(signatures[0].date)}
                                    </div>
                                    <div className="detail__content_description">
                                        {formatDateDMY(signatures[0].date)}
                                    </div>
                                </div>
                                <div className="detail__content_block">
                                    <div className="detail__content_name">
                                        {signatures[0].full_name}
                                    </div>
                                    <div className="detail__content_description">
                                        {signatures[0].inn}
                                    </div>
                                    <div className="detail__content_description">
                                        {signatures[0].phone}
                                    </div>
                                </div>
                            </div>
                        )}
                        {signatures?.[1]?.inn && (
                            <div className="detail__content_unsigned">
                                <div className="detail__content_title">
                                    <DoubleCheckIcon />&nbsp;
                                    На подписании
                                </div>
                                <div className="detail__content_block">
                                    <div className="detail__content_name">
                                        {formatTime(signatures[1].date)}
                                    </div>
                                    <div className="detail__content_description">
                                        {formatDateDMY(signatures[1].date)}
                                    </div>
                                </div>
                                <div className="detail__content_block">
                                    <div className="detail__content_name">
                                        {signatures[1].full_name}
                                    </div>
                                    <div className="detail__content_description">
                                        {signatures[1].inn}
                                    </div>
                                    <div className="detail__content_description">
                                        {signatures[1].phone}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="detail__verification">
                        <div className="detail__verification_content">
                            <div className="detail__verification_key">
                                <KeyIcon />
                            </div>
                            <div className="detail__verification_text">
                                Проверить документ
                            </div>
                            <div className="detail__verification_help">
                                <QuestionIcon />
                            </div>
                        </div>
                    </div>
                    <div className="detail__controls">
                        <Controls
                            documentUrl={documentUrl}
                            isSigned={signed}
                            isSignedByUser={is_signed_by_user}
                            redirectTo={() => history.push(`/case/${id}`)}
                            id={public_id}
                        />
                    </div>
                </div>
            </div>
        </Layout>

        // <div className="Page PageDetails">
        //     <div className="PageDetails_nav">
        //         {/* <Link
        //         to={`/profile?show=${signType}`}>
        //         {signLabel}
        //     </Link> */}
        //     </div>
        //
        //     <h1 className="Title Page_title">
        //         {formatCertName(document.filename)}
        //     </h1>
        //
        //     {!hasPermissionToSign &&
        //     <Alert className="PageDetails_alert">
        //         Вы не уполномочены подписывать документы электронной подписью. Вы можете только добавлять документы на подпись.
        //     </Alert>
        //     }
        //
        //     <TheFrame>
        //         <Document
        //             document={document}
        //             hasPermissionToSign={hasPermissionToSign}
        //             profile={profile}
        //         />
        //     </TheFrame>
        // </div>
    )
}

export default connect(
    state => ({
        documents: state.documents,
        profile: state.auth.profile,
    }),
)(Detail)
