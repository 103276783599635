import React from 'react'
import DocumentsListItem from './DocumentsListItem'
import './DocumentsList.scss'

export default function ({ documents = [] }) {
    return (
       <div className="DocumentsList">
        <ul className="DocumentsList_rows">
            {documents.map((document, index) => (
                <li key={`key_${index}`} className="DocumentsList_row">
                    <DocumentsListItem document={document} index={index+1}/>
                </li>
            ))}
        </ul>
       </div>
   )
}
