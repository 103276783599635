import React, {useState} from 'react'
import Button from '../Button'
// import {ReactComponent as Avatar} from '../../assets/icons/avatar.svg'
// import Avatar from '../../assets/img/avatar.png';
import Avatar from '../../assets/img/avatar-shadow.png';
import Logo from '../../assets/img/logo.png';
import {useDispatch, useSelector} from 'react-redux'
import {Link as RouterLink} from 'react-router-dom'
// import Link from '../../components/Link'

// import {formatPhone} from '../../../../utils/format'
import * as STATUSES from '../../../../store/profile.statuses'
import './header.scss'
import HeaderSignout from './HeaderSignout/HeaderSignout'
import classNames from "classnames/bind";
import authService from '../../../../services/auth';

export default function ({className = '', isHiddenProfile}) {
    // const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)
    const [display, setDisplay] = useState(false)

    return (
        <>
            {!auth.token ?
                <RouterLink style={{textDecoration: "none"}} to="/" className={`${className} Header_legium`}>
                    {/*<b className="Header_logo">Legium.io</b>*/}
                    <img className="Header_logo" src={Logo} alt=""/>
                </RouterLink> : null
            }
            {auth.token ? (
                <header className={classNames(`${className} DocumentsHeader`, {
                    "DocumentsHeader--hide": isHiddenProfile
                })}>
                    {auth?.profile?.status === STATUSES.EMPTY ? (
                        <b
                            onClick={() => {
                                authService.logout();
                            }}
                            className="Header_logo"
                        >
                            legium.io
                        </b>
                    ) : (
                        <RouterLink style={{textDecoration: "none"}} to="/profile">
                            <b className="Header_logo">
                                legium.io
                            </b>
                        </RouterLink>
                    )}
                    <div className={classNames("Header_controls", {
                        "Header_controls--hide": isHiddenProfile
                    })}>
                        {auth.profile ? (
                            <div className="Header_user">
                                {/* <RouterLink to="/profile">
                                {auth.profile.username && formatPhone(auth.profile.username)}
                        </RouterLink> */}
                                {/* {auth.profile.status !== STATUSES.EMPTY
                            ? <div className="Header_userCompany Text Text__sm">
                                {auth.profile.legal_name}
                            </div>
                            : null
                        } */}
                            </div>
                        ) : null}
                        <div className="SideHeader">
                            <Button className="RateBtn">
                                Улучшить тариф
                            </Button>
                            {/*<Avatar className="Header_signout_options" onClick={()=> {setDisplay(!display)}}/>*/}
                            <div className="Header_signout_options">
                                <div className="Header_signout_options_avatar">
                                    <img src={Avatar} />
                                </div>
                                <div className="Header_signout_options_pseudo" onClick={()=> {setDisplay(!display)}} />
                            </div>
                            {display ? <HeaderSignout /> : null}
                        </div>
                    </div>
                </header>
            ) : null}
        </>
    )
}
