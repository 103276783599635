export const initialState = {
  loading: false,
  loaded: false,
  data: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'USER_DOCUMENTS/LOADING':
      return {...state, loading: true, loaded: false}
    case 'USER_DOCUMENTS/SET':
      return {...state, loading: false, loaded: true, data: action.data}
    case 'DOCUMENTS/DELETE':
      const filteredData = state.data.filter(item => item.public_id !== action.id)
      return {...state, data: filteredData}
    default:
      return state
  }
}
