import React, {useState, useMemo, useEffect} from 'react'
import Input from '../Input'
import Button from '../Button/index'
import FormField from '../FormField/index'
import PhoneInput from '../InputPhone/index'
import Form from '../Form/index'
import Link from '../Link/index'
import Spinner from '../Spinner/Spinner'
import {ReactComponent as Checkbox} from "../../assets/icons/checkbox.svg"
import {requestAuthCode, requestAuthToken} from '../../../../store/actions/auth'
import {useDispatch, useSelector} from 'react-redux'
import ReactCodeInput from 'react-code-input'
import CheckBox from 'arui-feather/checkbox'
import CodeInput from '../CodeInput';

import './SigninForm.scss'
import {useHistory} from "react-router-dom";

export const LOGIN_STEP_PHONE = 1
export const LOGIN_STEP_PIN = 2
export const ERR_INCORRECT_PIN = 'Неверный код'
export const ERR_INCORRECT_PHONE = 'Введен неверный номер телефона'
export const ERR_INCORRECT_AGREEMENT = 'Вы должны принять условия использования, чтобы продолжить'


export default function ({className = '', submit}) {
    const initialStep = LOGIN_STEP_PHONE
    const [phone, setPhone] = useState('')
    const [pin, setPin] = useState('')
    const [step, setStep] = useState(initialStep)
    const [isPhoneIncorrect, setPhoneIncorrect] = useState(false)
    const [isPinIncorrect, setPinIncorrect] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const loading = useSelector((state) => state.auth.loading)
    const dispatch = useDispatch()
    const ButtonLabel = step === LOGIN_STEP_PHONE ? 'Войти' : 'Продолжить'
    const [minutes, setMinutes ] = useState(0);
    const [seconds, setSeconds ] =  useState(30);

    const validateCode = pin => pin && pin.length === 6
    const [inputKey, setInputKey] = useState(0)
    const isCodeValid = useMemo(() => validateCode(pin), [pin])

    const clearError = () => {
        if (step === LOGIN_STEP_PHONE) {
            setPhoneIncorrect(false)
        } else if (step === LOGIN_STEP_PIN){
            setPinIncorrect(false)
        }
    }

    function onPhoneChange(phoneNumber) {
        setPhone(phoneNumber)
        setPhoneIncorrect(false)
    }

    function nextStep() {
        if (checkAgreement()) return;
        setSeconds(30);
        if (step === LOGIN_STEP_PHONE) {
            try {
                dispatch(requestAuthCode(phone))
                setStep(LOGIN_STEP_PIN)
            } catch (err) {
                setPhoneIncorrect(true)
            }
        }
    }
    function resendPhone() {
        setSeconds(30);
        try {
            dispatch(requestAuthCode(phone))
            setStep(LOGIN_STEP_PIN)
        } catch (err) {

        }
    }
    function checkAgreement() {
        if (!isChecked) {
            setIsDisabled(true);
            return true;
        }
        return false;
    }

    useEffect(() => {
        async function FinalStep (){
            if (step === LOGIN_STEP_PIN) {
                try {
                    await dispatch(requestAuthToken(phone, pin));
                    setInputKey(inputKey + 1)
                    submit()
                } catch (err) {
                    setPin()
                    setPinIncorrect(true)
                }
            }
        }
        FinalStep()
    }, [isCodeValid])


    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(30);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });


    return (
        <div className={`Signin ${className}`}>
            <Form onSubmit={nextStep}>
                {step === LOGIN_STEP_PHONE && (
                    <div className="Signin-formfield">
                        <div className="Signin_title">Вход</div>
                        <div className="Signin_subtitle">Введите ваш номер телефона</div>
                        <PhoneInput
                            name="phone"
                            value={phone}
                            onChange={onPhoneChange}
                            onFocus={clearError}
                            placeholder="+974 999 000 00 00"
                            width="available"
                            className="input_signin"
                        />
                        {isPhoneIncorrect ? <div className="Input_error">{ERR_INCORRECT_PHONE}</div> : ''}
                        <Button
                            className="Signin_btn"
                            type="submit"
                            view="extra"
                            disabled={isDisabled ? 'disabled' : ''}
                        >
                            {ButtonLabel}
                        </Button>
                        {isDisabled ? <div className="Input_error">{ERR_INCORRECT_AGREEMENT}</div> : ''}
                        <div className="CheckBox">
                            {/*<Checkbox style={{width:"18", height:"18"}}/>*/}
                            <CheckBox
                                size="m"
                                checked={isChecked}
                                onChange={(isChecked, value) => {
                                    setIsChecked(isChecked);
                                    if (isChecked) {
                                        setIsDisabled(false);
                                    }
                                }}
                            />
                            <span className="CheckBox_text">
                                Согласен с условиями <a target="_blank" href="https://legium.io/terms" className="Mark">публичной оферты</a>
                            </span>
                        </div>
                    </div>
                )}

                {step === LOGIN_STEP_PIN && (
                    <div className="Signin-formfield">
                        <div className="Signin_title">Вход</div>
                        <div className="Signin_subtitle_last">
                            Для продолжения введите код отправленный на <b>{phone}</b>
                        </div>
                       {/*<ReactCodeInput*/}
                       {/*   key={inputKey}*/}
                       {/*   value={pin}*/}
                       {/*   fields={6}*/}
                       {/*   type="text"*/}
                       {/*  //  disabled={isCodeValid}*/}
                       {/*   onChange={setPin}*/}
                       {/*   onFocus={clearError}*/}
                       {/*   width="available"*/}
                       {/*   // mask={'111111'}*/}
                       {/*   placeholder='123456'*/}
                       {/* />*/}
                        <CodeInput
                            onFocus={clearError}
                            setPin={setPin}
                            isError={isPinIncorrect && !isCodeValid}
                        />
                        {isPinIncorrect && !isCodeValid && <div className="Input_error">{ERR_INCORRECT_PIN}</div>}
                        <div className="Signin_bottom_text">
                            {minutes === 0 && seconds === 0 ? (
                                <div style={{cursor: 'pointer'}} onClick={() => resendPhone()}>Отправить еще раз</div>
                            ) : (
                                <>
                                    Отправить еще раз через <b className="Mark">{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</b>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Form>
        </div>
    )
}
