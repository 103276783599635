import { callAPI } from '../utils/api'

export default {
    async commitOfficialData ({ inn, email, legal_form }) {
        return await this.sendProfile({ inn, email, legal_form })
    },

    async commitPersonData ({ main, registration ,selfie, ...rest }) {
        const responseMain = await this.sendPassportMain(main)
        const responseRegistration = await this.sendPassportRegistration(registration)
        const responseSelfie =  await this.sendPassportSelfie(selfie)
        const req = {
            passport_registration: responseRegistration.data.id,
            passport_selfie: responseSelfie.data.id,
            passport_main: responseMain.data.id,
            ...rest
        }
        return this.sendProfile(req)
    },

    async sendPassportMain (file) {
        const form = new FormData
        form.append('file', file)
        form.append('kind', 'passport_main')
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await callAPI('profile/identification/', form, headers, 'post')
    },

    async sendPassportRegistration (file) {
        const form = new FormData
        form.append('file', file)
        form.append('kind', 'passport_registration')
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await callAPI('profile/identification/', form, headers, 'post')
    },

    async sendPassportSelfie (file) {
        const form = new FormData
        form.append('file', file)
        form.append('kind', 'passport_selfie')
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await callAPI('profile/identification/', form, headers, 'post')
    },

    async sendProfile (data) {
        return await callAPI('profile/', data, null, 'put')
    }
}
