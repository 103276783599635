import React, {useState} from 'react'
import classNames from "classnames/bind"
import './DocumentsListItem.scss'
import { useHistory } from 'react-router-dom'

import {ReactComponent as Illustration} from '../../assets/img/illustrations/doc.svg'
import Path from "../../assets/img/Path.png"
import Union from "../../assets/img/Union.png"
import {ReactComponent as Delete} from "../../assets/icons/delete.svg"
import {ReactComponent as Save} from "../../assets/icons/save.svg"
import {ReactComponent as Close} from "../../assets/icons/close.svg"

import { useDispatch} from "react-redux";
import {removeDocument, getDataFromURL} from "../../../../store/actions/documents"
import DownloadLink from "react-download-link";
import Modal from "react-modal";

export default function ({ document, index})   {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isopen, setIsopen ] = useState(false);
    const [isOpenItem, setOpenItem ] = useState(false);

    const navigate = (event, id) => {
        event.stopPropagation();
        history.push(`/case/${id}`);
    }
    const RemoveDocument= () => {
        dispatch(removeDocument(document.public_id))
        setIsopen(false);
    }
    const itemHandler = (event) => {
        if (window.innerWidth >= 1024) {
            navigate(event, document.public_id)
        } else {
            setOpenItem(!isOpenItem);
        }
    }

    return (
        <>
            <div
                className={classNames("DocumentsListItem", {
                    "DocumentsListItem--open": isOpenItem
                })}
                onClick={(event) => itemHandler(event)}
            >
                <span className="Overlay"></span>
                <span className="DocumentsList_index">{index}</span>
                <div className="DocumentsListItem_preview">
                    <Illustration />
                </div>
                <div className="DocumentsListItem_info">
                    <div className="DocumentsListItem_title">
                        {document.name}
                    </div>
                    <div className="DocumentsListItem_meta">
                        <div className="DocumentsListItem_company">
                            {[].concat(document.signatures || []).join(', ')}
                        </div>
                        {/*<div className="DocumentsListItem_person">*/}
                        {/*Васильев Николай Петрович*/}
                        {/*</div>*/}
                    </div>
                </div>
                {!document.signed ? (
                    <div className="DocumentsListItem_icon">
                        <img src={Path} />
                    </div>
                ) : (
                    <div className="DocumentsListItem_icon">
                        <img src={Union} />
                    </div>
                )}
                <div className="DocumentsListItem_overlay">
                    <div className="DocumentsListItem_icons">
                        {!document.signed && (
                            <span className="DocumentsListItem_icon_f">
                                <Delete onClick={(event)=>{
                                    event.stopPropagation();
                                    setIsopen(true);
                                }}/>
                            </span>
                        )}
                        <span className="DocumentsListItem_icon_l" onClick={(event) => event.stopPropagation()}>
                            <DownloadLink
                                label={<Save />}
                                filename={document.filename}
                                exportFile={() => Promise.resolve(getDataFromURL(document.url))}
                            />
                        </span>
                    </div>
                </div>
                <div className="DocumentsListItem__layer">
                    <div
                        className="DocumentsListItem__button"
                        onClick={(event)=>{
                            navigate(event, document.public_id)
                        }}
                    >
                        Перейти
                    </div>
                    <div
                        className="DocumentsListItem__button"
                        onClick={(event)=>{
                            event.stopPropagation();
                            setIsopen(true);
                        }}
                    >
                        Удалить
                    </div>
                    <div
                        className="DocumentsListItem__button"
                        onClick={(event)=>{
                            event.stopPropagation();
                        }}
                    >
                        <DownloadLink
                          label={"Скачать"}
                          filename={document.filename}
                          exportFile={() => Promise.resolve(getDataFromURL(document.url))}
                        />
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isopen}
                onRequestClose={()=>{setIsopen(false)}}
                className="DeleteModal"
            >
                <div className="DeleteModal-close">
                    <Close onClick={()=>{setIsopen(false)}} />
                </div>
                <div className="DeleteModal_content">
                    <b className="DeleteModal_header">Удаление документа</b>
                    <div className="DeleteModal_text">Вы уверены, что хотите удалить документ ?</div>
                </div>
                <div className="DeleteModal_btns">
                    <button className="DeleteModal_cancel" onClick={()=>{setIsopen(false)}}>Отмена</button>
                    <button className="DeleteModal_delete" onClick={RemoveDocument}>Удалить</button>
                </div>
            </Modal>
        </>
    )
}
