import React from 'react';
import './footer.scss';
import { Link } from 'react-router-dom'


export default function ({ className = '' }) {
    // const year = (new Date()).getFullYear()
    return (
        <footer className={`${className} Footer`}>
            <div className="Footer_copyright">
                © legium.io 
                {/* {year} */}
            </div>
            <div className="Footer_nav">
            <Link 
               className="Footer-item"
               to=""
               >
                Пользовательское соглашение
            </Link>
            <Link 
               className="Footer-item"
               to="">
                Политика конфиденциальности
            </Link>
            <Link 
               className="Footer-item"
               to="">
                Как это работает?
            </Link>
            </div>
        </footer>
    )
}