import axios from 'axios'
import {callAPI} from '../../utils/api'
import {API_DOMAIN} from '../../utils/const'
// import {AUTH_TOKEN_KEY } from "../../utils/const";
// import {authService} from '../../services/auth'

export const setToken = token => {
    return {type: 'AUTH/SET_TOKEN', token}
}

export const removeToken = () => {
    return {type: 'AUTH/REMOVE_TOKEN'}
}

export const authError = () => {
    return {type: 'AUTH/ERROR'}
}

export const authLoading = () => {
    return {type: 'AUTH/LOADING'}
}

export const authLoaded = profile => {
    return {type: 'AUTH/LOADED', profile}
}

export const loadProfile = (force = false) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        dispatch(authLoading())
        try {
            const response = await callAPI('user/')
            dispatch(authLoaded(response.data))
            resolve()
        } catch (error) {
            dispatch(authError())
            reject(error)
        }
    })
}

export const requestAuthCode = phoneNumber => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch({type: 'AUTH/REQUEST_CODE'})
        try {
            const response = await callAPI(`login/pin/`, {phone: phoneNumber})
            if (response.status === 200) {
                dispatch({type: 'AUTH/REQUEST_CODE_SUCCESS'})
                resolve(response)
            } else {
                dispatch({type: 'AUTH/REQUEST_CODE_ERROR'})
                reject(response)
            }
        } catch (error) {
            reject(error)
        }

    })
}

export const requestAuthToken = (phoneNumber, code) => dispatch => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(`${API_DOMAIN}/login/`, {
                phone: phoneNumber,
                pin: code,
            })

            if (response.status === 200) {
                resolve()
                dispatch(setToken(response.data.token))
            }
        } catch (error) {
            reject(error)
        }
    })
}
