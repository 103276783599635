import React from 'react'
import {Link} from 'react-router-dom'
import Error from './Error'
import Layout from '../../components/Layout'
import './PageError.scss';

export default function (props) {
    return (
        <Layout>
            <div className="no_match">
                <div className="no_match__block">
                    <div className="no_match__text">
                        Ошибка
                    </div>
                    <div className="no_match__exception">
                        Ссылка не найдена
                    </div>
                    <div className="no_match__link">
                        <Link to="/profile">
                            Вернуться на главную страницу
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
