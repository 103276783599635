import React, {useEffect, memo} from 'react'
import SigninForm from '../../components/Forms/SigninForm'
import TheFrame from '../../components/Frame/index'
import Layout from '../../components/Layout'
import './signin.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { loadUserDocuments } from "../../../../store/actions/userDocuments"
import { loadProfile } from "../../../../store/actions/auth"

export default memo(function () {
    const dispatch = useDispatch()
    const history = useHistory()
    const token = useSelector(state => state.auth.token)

    const submit = async () => {
        await dispatch(loadProfile())
        await dispatch(loadUserDocuments())
    }

    useEffect(() => {
        if (token) history.push('/profile')
    }, [token])

    return (
        <Layout>
            <div className="SigninContainer">
                <TheFrame>
                    <SigninForm submit={submit}></SigninForm>
                </TheFrame>
            </div>
        </Layout>
    )
})
