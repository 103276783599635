import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import TabsHeader from './TabsHeader';
import StepFirst from './StepFirst';
import StepSecond from './StepSecond';
import StepThird from './StepThird';

import TYPES from '../../../../store/customer.types';
import './PageRegistration.scss';
import * as validate from "../../../../utils/validate";
import profileService from "../../../../services/profile";
import {loadProfile} from "../../../../store/actions/auth";
const LEGAL_FORM_KEY = 'legal_form';
import {STEPS} from '../../../../utils/const';

const typesInfo = {
    [TYPES.TYPE_PERSON]: 'Физическое <br />  лицо',
    [TYPES.TYPE_ENTERPRENEUR]: 'Индивидуальный <br /> предприниматель',
    [TYPES.TYPE_ORGANIZATION]: 'Юридическое <br />  лицо'
}

export default withRouter(function ({history, dispatch}) {
    const [step, setStep] = useState(STEPS.first);
    const [isLoading, setIsLoading] = useState(false);

    const [isValidFirstStep, setValidFirstStep] = useState(false);
    const [isValidSecondStep, setValidSecondStep] = useState(false);
    const [isValidThirdStep, setValidThirdStep] = useState(false);

    // const [legalForm, setLegalForm] = useState(legalFormFromUrl)
    const [legalForm, setLegalForm] = useState('');
    const [inn, setInn] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState([]);
    const [photo, setPhoto] = useState([]);
    const [video, setVideo] = useState([]);
    const [innError, setInnError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [filesError, setFilesError] = useState(null);
    const isValid = address.length && photo.length && video.length;

    async function submit () {
        const data = { inn, email, legal_form: legalForm }
        let einnError = null;
        // if (!inn) {
        //     einnError = 'Некорректный ИНН'
        // } else if (legalForm === TYPES.TYPE_ORGANIZATION && inn.length !== 10) {
        //     einnError = 'ИНН физ. лица должен содержать 10 символов'
        // } else if (legalForm !== TYPES.TYPE_ORGANIZATION && inn.length !== 12) {
        //     einnError = 'ИНН юр. лица должен содержать 12 символов'
        // }
        if (!inn) {
            einnError = 'Некорректный ИНН';
            setInnError(true);
            setInnError(einnError);
            return;
        }
        const eemailError = (validate.email(email) || !email) ? 'Некорректный e-mail' : null
        setIsLoading(true)
        setEmailError(eemailError)

        if (einnError || eemailError) {
            setIsLoading(false)
            return
        }

        if (legalForm === TYPES.TYPE_PERSON) {
            const hasFiles = photo.length && address.length && video.length
            setFilesError(hasFiles ? null : 'Пожалуйста, прикрепите файлы')
            if (!hasFiles) {
                setIsLoading(false)
                return
            }

            await profileService.commitPersonData({
                main: photo[0],
                registration: address[0],
                selfie: video[0],
                ...data
            }).then()
                .catch((error) => {})
        } else {
            await profileService.commitOfficialData(data)
        }

        await dispatch(loadProfile())
        setIsLoading(false)
        history.push('/profile')
    }

    return (
        <form className="page_registration Page">
            <div className="page_registration__card">
                <h1 className="Page Page_title Title page_registration__title">
                    Регистрация
                </h1>

                <TabsHeader
                    firstTabTitle={typesInfo[legalForm]}
                    setStep={(step) => setStep(step)}
                    submit={submit}
                    step={step}
                    isValidFirstStep={isValidFirstStep}
                    isValidSecondStep={isValidSecondStep}
                    isValidThirdStep={isValidThirdStep}
                    isPersonal={legalForm === TYPES.TYPE_PERSON}
                    innError={innError}
                >
                    <>
                        {step === STEPS.first && (
                            <StepFirst
                                setLegalForm={(data) => {
                                    setValidFirstStep(true);
                                    setLegalForm(data);
                                }}
                                legalForm={legalForm}
                            />
                        )}
                        {step === STEPS.second && (
                            <StepSecond
                                setInn={(inn) => setInn(inn)}
                                setEmail={(inn) => setEmail(inn)}
                                innError={innError}
                                emailError={emailError}
                                setInnError={setInnError}
                                setEmailError={setEmailError}
                                inn={inn}
                                email={email}
                                setValidSecondStep={() => setValidSecondStep(true)}
                                legalForm={legalForm}
                            />
                        )}
                        {step === STEPS.third && (
                            <StepThird
                                setAddress={(address) => setAddress(address)}
                                setPhoto={(photo) => setPhoto(photo)}
                                setVideo={(video) => setVideo(video)}
                                isPersonal={legalForm === TYPES.TYPE_PERSON}
                                setValidThirdStep={(valid) => setValidThirdStep(valid)}
                            />
                        )}
                    </>
                </TabsHeader>
            </div>
        </form>
    )
})
