import React from 'react'
import classNames from 'classnames/bind'
import '../../assets/styles/index.scss'
import './layout.scss'
import PageHeader from '../Header'
import PageFooter from '../Footer'

export default function (
    {
        className = '',
        children,
        isAutoHeight = false,
        isFooterHidden = false,
        isHiddenProfile = false
    }) {
    return (
        <div className={`${className} layout`}>
            <div>
                <PageHeader className="layout__header" isHiddenProfile={isHiddenProfile}/>
                <main className={classNames("layout__main", {
                    "layout__main--auto": isAutoHeight
                })}>
                    {children}
                </main>
            </div>
            <PageFooter
                className={classNames("layout__footer", {
                    "layout__footer--auto": isAutoHeight,
                    "layout__footer--hide": isFooterHidden
                })}
            />
        </div>
    )
}
