import React from 'react'
import axios from 'axios'
import {API_DOMAIN, AUTH_TOKEN_KEY} from './const'
import authService from "../services/auth";
// import {authError} from 'actions'

export const callAPI = async (endpoint, data = null, headers = {}, method = null) => {
  const token = authService.getToken()

  const suggestedMethod = data ? 'post' : 'get'
  const requestMethod = method ? method : suggestedMethod
  const requestHeaders = {}
  if (token) {
    requestHeaders['Authorization'] = `Token ${token}`
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: requestMethod,
        url: `${API_DOMAIN || ''}/${endpoint}`,
        data,
        headers: {
          ...requestHeaders,
          ...headers,
        },
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          authService.removeToken()
        }
        throw err
      })
      // console.log('response in call api: ', response)
      resolve(response)
    } catch (error) {
      // console.log('error in call api', error)
      reject(error)
    }
  })
}
