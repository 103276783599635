import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames/bind";
import './styles.scss';

const CodeInput = (
    {
        isError,
        setPin,
        onFocus
    }
) => {
    const refOne = useRef();
    const refTwo = useRef();
    const refThree = useRef();
    const refFore = useRef();
    const refFive = useRef();
    const refSix = useRef();
    const [valueOne, setValueOne] = useState('');
    const [valueTwo, setValueTwo] = useState('');
    const [valueThree, setValueThree] = useState('');
    const [valueFore, setValueFore] = useState('');
    const [valueFive, setValueFive] = useState('');
    const [valueSix, setValueSix] = useState('');

    useEffect(() => {
        const values = [valueOne, valueTwo, valueThree, valueFore, valueFive, valueSix];
        const refs = [refOne, refTwo, refThree, refFore, refFive, refSix];
        for (let i = 0; i < values.length; i++) {
            if (values[i].length !== 0 && refs[i + 1] !== undefined) {
                refs[i + 1].current.focus();
            } else {
                break;
            }
        }
        if (
            valueOne.length !== 0
            && valueTwo.length !== 0
            && valueThree.length !== 0
            && valueFore.length !== 0
            && valueFive.length !== 0
            && valueSix.length !== 0
        ) {
            setPin(`${valueOne}${valueTwo}${valueThree}${valueFore}${valueFive}${valueSix}`)
        }
    }, [
        valueOne,
        valueTwo,
        valueThree,
        valueFore,
        valueFive,
        valueSix
    ])

    const setValue = (value, setter) => {
        if (!Number.isInteger(Number(value))) {
            setter('');
            return;
        }
        onFocus();
        if (value?.length > 1) {
            const setters = [setValueOne, setValueTwo, setValueThree, setValueFore, setValueFive, setValueSix];
            for (let i = 0; i < setters.length; i++) {
                setters[i](value[i])
            }
        } else {
            setter(value);
        }
    }
    const getInput = (index, setter, ref, value) => {
        return (
            <div className="code_input__item">
                <input
                    className="code_input__input"
                    type="number"
                    tabIndex={index}
                    ref={ref}
                    value={value}
                    onChange={({target}) => setValue(target.value, setter)}
                    placeholder={`${index}`}
                    maxLength="1"
                />
            </div>
        )
    }
    return (
        <div className={classNames('code_input', {
            "code_input--error": isError
        })}>
            {getInput(1, setValueOne, refOne, valueOne)}
            {getInput(2, setValueTwo, refTwo, valueTwo)}
            {getInput(3, setValueThree, refThree, valueThree)}
            {getInput(4, setValueFore, refFore, valueFore)}
            {getInput(5, setValueFive, refFive, valueFive)}
            {getInput(6, setValueSix, refSix, valueSix)}
        </div>
    )
}

export default CodeInput;
