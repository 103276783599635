import React, {useState} from 'react';
import classNames from "classnames/bind";
import Input from 'arui-feather/input';
import {ReactComponent as Close} from "../../../assets/icons/close-small.svg";
import {ReactComponent as Mail} from "../../../assets/icons/mail.svg";

import './styles.scss';
import {callAPI} from "../../../../../utils/api";

const SendEmail = ({id, close, isSmall}) => {
    const [value, setValue] = useState('');
    const submit = async () => {
        if (value.length) {
            await callAPI(`case/${id}/email/${value}`, null);
            setValue('');
            close?.();
        }
    }

    return (
        <div className="send_email">
            <Input
                className={classNames('send_email__input', {
                    'send_email__input--small': isSmall
                })}
                placeholder="example@gmail.com"
                onChange={setValue}
                value={value}
            />
            <div className="send_email__submit" onClick={submit}>
                <Mail />
            </div>
            {!!close && (
                <div className="send_email__close" onClick={() => close()}>
                    <Close />
                </div>
            )}
        </div>
    )
}

export default SendEmail;
