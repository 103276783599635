import React from 'react';
import TheFrame from "../../components/Frame";

const ProfileDenied = props => {
    return (
        <div className="page">
            <TheFrame isCard={true}>
                <h1 className="page__title">Регистрация не завершена</h1>

                <p className="page__text">Ваш профиль не годен для использования. Обратитесь в нашу службу поддержки.</p>
            </TheFrame>

        </div>
    )
}

export default ProfileDenied
