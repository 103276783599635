import React, {useEffect, useState} from "react";
import classNames from "classnames/bind";
import './styles.scss';

import ArrowDashed from '../../../../assets/img/arrow-right-dashed.png';
import ArrowSolid from '../../../../assets/img/arrow-right-solid.png';
import ArrowM from '../../../../assets/img/m-arrow.png';

const Tab = (
    {
        isActive = false,
        isValid,
        onNext,
        onEdit,
        title: titleProps = '',
        number = 1,
        innError
    }) => {
    const [title, setTitle] = useState(titleProps);

    const nextHandle = () => {
        onNext();
    };
    const editHandle = () => {
        onEdit();
    };
    useEffect(() => {
        if (titleProps) {
            setTitle(titleProps);
        }
    }, [titleProps]);
    return (
        <div
            className={classNames("tab", {
                "tab--valid": isValid || isActive,
                "tab--invalid": innError,
                "tab--active": isActive
            })}
        >
            <div className="tab__arrow tab__arrow--dashed">
                <img src={ArrowDashed} alt=""/>
            </div>
            <div className="tab__arrow tab__arrow--solid">
                <img src={ArrowSolid} alt=""/>
            </div>
            <div className="tab__arrow tab__arrow--mobile">
                <img src={ArrowM} alt=""/>
            </div>
            <div className="tab__count">{number}</div>
            <div className="tab__block">
                <div className="tab__title" dangerouslySetInnerHTML={{__html: title}} />
                {/*    {title}*/}
                {/*</div>*/}
                {isActive && isValid && (
                    <div
                        className="tab__text_button tab__text_button--active"
                        onClick={nextHandle}
                    >
                        Далее
                    </div>
                )}
                {!isActive && isValid && (
                    <div
                        className="tab__text_button"
                        onClick={editHandle}
                    >
                        Изменить
                    </div>
                )}
            </div>
        </div>
    )
}

export default Tab;
