// import {Routes as base_routes} from '../projects/Legium-base/Routes';
// import {Routes as mail_ru_routes} from '../projects/MailRu/Routes';
// import {Routes as skillbox_routes} from '../projects/Skillbox/Routes';
import {Routes as sign_routes} from '../projects/Sign/Routes';

export const IS_DEV = process.env.REACT_APP_NODE_END !== 'development';
// export const API_DOMAIN = IS_DEV ? process.env.API_DOMAIN : 'https://api.legium.io';
export const API_DOMAIN = IS_DEV ? process.env.REACT_APP_API_DOMAIN : 'https://api.legium.io';
export const AUTH_TOKEN_KEY = 'AUTH_TOKEN';
export const PROJECTS = [
  // {
  //   name: 'MAIL_RU',
  //   routes: mail_ru_routes
  // },
  // {
  //   name: 'SKILLBOX',
  //   routes: skillbox_routes
  // },
  {
    name: 'SIGN',
    routes: sign_routes
  },
  // {
  //   name: 'BASE',
  //   routes: base_routes
  // },
];

export const STEPS = {
  first: 'first',
  second: 'second',
  third: 'third'
}

