import React from "react";
import {connect} from 'react-redux';
import { Route, Switch, Link } from "react-router-dom";
import {ReactComponent as Documents} from "../../../assets/icons/documents.svg";
import {ReactComponent as Personal} from "../../../assets/icons/personal.svg";
import {ReactComponent as SignOut} from "../../../assets/icons/signout.svg";
import "./headersignout.scss";
import authService from '../../../../../services/auth'

const HeaderSignout = ({phone, name}) => {

 return (
         <div className="HeaderSignout top_triangle">
                <Link
                    to="/profile/personalzone"
                    style={{textDecoration: "none"}}
                    className="HeaderSignout_link"
                >
                    <Personal/>
                   <div className="HeaderSignout_text">{phone}<div className="HeaderSignout_text_t">{name}</div></div>
                </Link>
                <Link
                    to="/profile"
                    style={{textDecoration: "none"}}
                    className="HeaderSignout_link"
                >
                   <Documents/>
                  <div className="HeaderSignout_text">Документы</div>
               </Link>
            <div className="HeaderSignout_link">
                    <SignOut/>
                   <div
                       className="HeaderSignout_text"
                       onClick={() => {
                           authService.logout()
                       }}
                   >
                       Выйти
                   </div>
            </div>
        </div>
 )
}

export default connect(
    (state) => ({
        phone: state.auth.profile.username,
        name: state.auth.profile.legal_name
    }),
    null
)(HeaderSignout);
