export const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'DOCUMENTS/ADD':
      const doc = {
        status: action.data.status,
        data: action.data,
      }
      return {...state, [action.id]: doc}
    case 'DOCUMENTS/LOADING':
      const loadingDoc = {
        status: null,
        data: null,
      }
      return {...state, [action.id]: loadingDoc}
    case 'DOCUMENTS/RENAME':
      return {...state, [action.id]: {...state[action.id], name: action.name}}
    case 'AUTH/REMOVE_TOKEN':
      return initialState
    default:
      return state
  }
}

