import React, { useState, useRef, useEffect, useCallback } from 'react'
// import Dropzone from 'arui-feather/dropzone'
import {useDropzone} from 'react-dropzone'
import {ReactComponent as Docinfo} from "../../assets/icons/docinfo.svg"
import {ReactComponent as Doc} from "../../assets/img/illustrations/doc.svg"
import {ReactComponent as Clear} from "../../assets/icons/clear.svg"
import './DocumentDropzone.scss'

export default function (
    {
        onChange,
        className = '',
        disabled = false,
        setUploaded,
        hasNotFile,
        ...rest
    }) {
    const [file, setFile] = useState(null)
    const [dragged, setDragged] = useState(false)
    // const hiddenInput = useRef()

    useEffect(() => {
        setUploaded(!!file)
    }, [file])
    useEffect(() => {
        if (!hasNotFile) {
            setFile('')
        }
    }, [hasNotFile])

    // function change (fileList) {
    //     // console.log(fileList)
    //     const newFile = (fileList && fileList.length) ? fileList[fileList.length - 1] : null
    //     setFile(newFile)
    //     onChange && onChange(newFile)
    // }
    const onDrop = useCallback(acceptedFiles => {
        // setIsUpload(true);
        setFile(acceptedFiles[0]);
        // emitFile(acceptedFiles);
        onChange?.(acceptedFiles[0])
    }, [])
    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: ".pdf"
    })
    const remove = () => {
        // setIsUpload(false);
        console.log(file);
        setFile(null);
        // emitFile([])
        onChange?.(null)
    }

    return (
        <div
            // onDrop={change}
            // onDragEnter={() => setDragged(true)}
            // onDragLeave={() => setDragged(false)}
            className={!file ? "DocumentDropzone DocumentDropzone_active" : "DocumentDropzone"}
            // onClick={() => hiddenInput.current?.click?.()}
            {...getRootProps()}
        >
            <div className="DocumentDropzone_content">
                {file ? (
                        <div className="DocumentDropzone_files" >
                            <Doc className="DocumentDropzone_doc"/>
                            <div className="DocumentDropzone_file">
                                <div className="DocumentDropzone_fileName">{file.name}</div>
                                <div className="DocumentDropzone_size">PDF<span className="DocumentDropzone_mark">{file.size}</span>Mb</div>
                            </div>
                            {/*<Clear className="DocumentDropzone_delete" onClick={() => change([])}/>*/}
                            <Clear className="DocumentDropzone_delete" onClick={remove}/>
                        </div>
                    ) : (
                        <div >
                            <div className="DocumentDropzone_linkWrapper">
                                <div
                                    className="DocumentDropzone_appendLink"
                                    // disabled={disabled}
                                >
                                    <Docinfo/>
                                    <input
                                        // type="file"
                                        // ref={hiddenInput}
                                        // style={{display: 'none'}}
                                        // onChange={e => change(e.target.files)}
                                        {...getInputProps()}
                                    />
                                </div>
                            </div>
                            <div className="DocumentDropzone_hint">
                                <p className="DocumentDropzone_hint_f">Перетяните файл в это поле</p>
                                <p className="DocumentDropzone_hint_s">Или кликните, чтобы открыть окно загрузки</p>
                                <p className="DocumentDropzone_hint_l">Поддерживаемый формат *.PDF</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
