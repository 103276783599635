import React, {Fragment, useEffect, memo} from 'react'
import {connect, useDispatch} from 'react-redux'
import ProfileDenied from './Denied'
import ProfilePending from './Pending'
import Documents from '../Document/Documents'
import Registration from '../Registration/Registration'
import * as STATUSES from '../../../../store/profile.statuses'
import Layout from '../../components/Layout'
import {loadProfile} from "../../../../store/actions/auth";
// import {loadUserDocuments} from "../../../../store/actions/userDocuments";

const statusToComponent = {
    [STATUSES.APPROVED]: Documents, //ProfileDetail,
    [STATUSES.DENIED]: ProfileDenied,
    [STATUSES.EMPTY]: Registration, //ProfileInit,
    [STATUSES.PENDING]: ProfilePending,
}

const Profile = ({profile, dispatch}) => {
    let status = null
    if (profile) {
        status = profile.status
    }

    useEffect(() => {
        if (!profile) {
            dispatch(loadProfile());
        }
    })
    // dispatch(loadUserDocuments());

    const StatusComponent = statusToComponent[status];

    return (
        <Layout isAutoHeight={true} isHiddenProfile={status === STATUSES.EMPTY}>
            {StatusComponent && <StatusComponent dispatch={dispatch} />}
        </Layout>
    )
}

export default connect(
    state => ({profile: state.auth.profile}),
    null,
)(Profile)
