import React, { useState } from 'react'
import Input from '../Input'
import Form from '../Form'
import FormField from '../FormField'
import Button from '../Button'
import DocumentDropzone from '../DocumentDropzone/DocumentDropzone'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createCase } from '../../../../store/actions/documents'
import './NewDocumentForm.scss'
import upload from '../../../../services/upload'
import {ReactComponent as Clear} from "../../assets/icons/clear.svg"

const ERR_UPLOAD_FAIL = 'Не удалось загрзуить документ.'

const NewDocumentForm = ({partner, history, dispatch}) => {
    const [url, setURL] = useState('')
    const [error, setError] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [uploaded, setUploaded] = useState(false)

    const onFileChange = async (event) => {
        if (!event) {
            setURL('')
            return
        }

        setUploading(true)
        try {
            const { local_url } = await upload.uploadDocument(event)
            setURL(local_url)
            setError(null)
        } catch (err) {
            console.error(err)
            setError('Не удалось загрузить документ')
        }
        setUploading(false)
    }

    async function submit () {
        try {
            const response = await dispatch(createCase(url))
            history.push(`/case/${response.data.id}`)
        } catch (err) {
            const { response } = err
            if (!response) return ERR_UPLOAD_FAIL
            // 400ая может прийти, если регистрационные данные пользователя еще не проверили,
            // а также если пытаемся отправить запрос без загруженного файла. Поэтому для текста
            // ошибки про "подождите конца регистрации" нужно ввёл доп. проверку на загруженный файл.
            const msg1 = uploaded && (response.status === 400 || response.status === 401)
                ? 'Пожалуйста, подождите, пока мы подтвердим вашу регистрацию.'
                : null
            const msg2 = response.data ?
                (response.data.error || ERR_UPLOAD_FAIL)
                : ERR_UPLOAD_FAIL
            setError(msg1 || msg2)
        }
    }

    function deleteMessage () {
        setError(false)
    }

    const ClearUrl = () => {
        setURL('')
    }

    return <Form onSubmit={submit} className="NewDocumentForm">
        <FormField className="NewDocumentForm_field">
            <Input
                className="NewDocumentForm_input"
                value={url}
                onChange={setURL}
                onFocus={deleteMessage}
                placeholder="https://"
                width="available"
                error={error}
                />
                {url ? <div className="NewDocumentForm_icon"><Clear onClick={ClearUrl}/></div> : null }
        </FormField>
        <div className="NewDocumentForm_title">Загрузка с компьютера<span className="NewDocumentForm_title_sign">?</span></div>
        <DocumentDropzone
            onChange={onFileChange}
            setUploaded={setUploaded}
            hasNotFile={url.length}
        />
        <Button className="NewDocumentForm_btn" type="submit" disabled={uploading || !url} view="extra" >Загрузить</Button>
    </Form>
}


export default connect(
    null,
    null,
)(withRouter(NewDocumentForm))

