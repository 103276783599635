import React, {useEffect, useState} from "react";

import './styles.scss';
// import Input from "../../../components/Input";
import Input from 'arui-feather/input'
import TYPES from "../../../../../store/customer.types";

const StepSecond = (
    {
        legalForm,
        setInn,
        setEmail,
        inn,
        email,
        innError,
        emailError,
        setInnError,
        setEmailError,
        setValidSecondStep
    }) => {
    // const mask = legalForm === TYPES.TYPE_PERSON ? '1111111111' : '111111111111'
    const [mask, setMask] = useState('111111111111111');
    const [innValid, setInnValid] = useState('');
    const [emailValid, setEmailValid] = useState('');

    useEffect(() => {
        legalForm === TYPES.TYPE_ORGANIZATION
            ? setMask('111111111111111')
            : setMask('111111111111111');
    });
    const emitData = (data, type) => {
        if (type === 'inn') {
            setInn(data);
            setInnValid(data);
        }
        if (type === 'email') {
            setEmail(data);
            setEmailValid(data);
        }
    }
    useEffect(() => {
        if (innValid.length <= mask.length && emailValid.length) {
            setValidSecondStep();
        }
    }, [innValid, emailValid])

    return (
        <div className="step_second">
            <div className="step_second__block">
                <div className="step_second__title">
                    Введите Ваш ИНН
                </div>
                <div className="step_second__row">
                    <Input
                        className="step_second__input step_second__input--inn"
                        name="inn"
                        // mask={mask}
                        error={innError}
                        value={inn}
                        onChange={(inn) => {
                            emitData(inn, 'inn')
                        }}
                        onFocus={() => setInnError(false)}
                        width="available"
                        placeholder="999  000  00  00"
                    />
                </div>
            </div>
            <div className="step_second__block">
                <div className="step_second__title">
                    Введите  Ваш e-mail адрес
                </div>
                <div className="step_second__row">
                    <Input
                        className="step_second__input"
                        name="email"
                        error={emailError}
                        value={email}
                        onChange={(email) => emitData(email, 'email')}
                        onFocus={() => setEmailError(false)}
                        width="available"
                        placeholder="email@email.com"
                    />
                </div>
            </div>
        </div>
    )
}

export default StepSecond;
