// import {setToken, loadProfile} from './index'
import {loadProfile} from './auth'
import {callAPI} from '../../utils/api'

export const requestSignatureCode = documentId => dispatch => {
  return new Promise(async (resolve, reject) => {
    dispatch({type: 'SIGNATURE/REQUEST_CODE'})
    const response = await callAPI(`signature/`, {
      case: documentId,
    })
    if (response.status === 200) {
      dispatch({type: 'SIGNATURE/REQUEST_CODE_SUCCESS'})
      resolve(response)
    } else {
      dispatch({type: 'SIGNATURE/REQUEST_CODE_ERROR'})
      reject(response)
    }
  })
}

export const commitSignature = (documentId, code) => dispatch => {
  return new Promise(async (resolve, reject) => {
    dispatch({type: 'SIGNATURE/COMMIT'})
    try {
      const response = await callAPI(`case/${documentId}/sign`, {
        pin: code,
      })
      dispatch({type: 'SIGNATURE/COMMIT_SUCCESS'})
      resolve(response)
    } catch (error) {
      dispatch({type: 'SIGNATURE/COMMIT_ERROR'})
      reject(error)
    }
  })
}

export const commitLoginSignature = (documentId, phone, code, demo) => dispatch => {
  return new Promise(async (resolve, reject) => {
    dispatch({type: 'SIGNATURE/COMMIT'})
    try {
      const response = await callAPI(`case/${documentId}/authsign`, {
        pin: code,
        phone,
        demo,
      })

      dispatch({type: 'SIGNATURE/COMMIT_SUCCESS'})
      // dispatch(setToken(response.data.token))
      if (response?.data?.token) {
        dispatch({type: 'AUTH/SET_TOKEN', token: response.data.token})
      }
      dispatch(loadProfile(true)).then(() => {
        resolve(response)
      })
    } catch (error) {
      dispatch({type: 'SIGNATURE/COMMIT_ERROR'})
      reject(error)
    }
  })
}
