// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
import 'bootstrap/dist/css/bootstrap.min.css'

// import App from './App'
// export default App

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
