import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './DocumentsEmpty.scss'
import {ReactComponent as Doc} from "../../assets/img/illustrations/doc-large.svg"
import {ReactComponent as Vector} from "../../assets/img/Vector.svg"
import {ReactComponent as Upload} from '../../assets/icons/upload.svg'
import Figure from "../../components/Figure/Figure"

export default function () {
    const history = useHistory()

    const newDocument = () => history.push('/case/new')

    return (
        <Figure>
            <div className="documents_empty__header">
                <span>
                    <b>Документы</b>
                </span>
                <div className="documents_empty__upload_icon">
                    <Upload onClick={newDocument}/>
                    <div className="documents_empty__arrow">
                        <Vector />
                    </div>
                </div>
            </div>
            <div className="documents_empty__content">
                <div>
                    <p className="documents_empty__text_f">У вас нет загруженных документов</p>
                    <p className="documents_empty__text_s">Загрузите Ваш первый документ</p>
                    <Doc/>
                </div>
                <span className="documents_empty__text_l">Поддерживаемый формат *.PDF</span>
            </div>
        </Figure>
    )
}
