import {callAPI} from '../../utils/api'

const mock = [
  {
    public_id: 'test id',
    name: 'test name',
    signatures: [],
    filename: '123',
    url: '12333',
    signed: true
  },
  {
    public_id: 'test id',
    name: 'test name',
    signatures: [],
    filename: '123',
    url: '12333',
    signed: false
  },
  {
    public_id: 'test id',
    name: 'test name',
    signatures: [],
    filename: '123',
    url: '12333',
    signed: true
  },
  {
    public_id: 'test id',
    name: 'test name',
    signatures: [],
    filename: '123',
    url: '12333',
    signed: false
  }
];

export const loadingUserDocuments = () => {
  return {type: 'USER_DOCUMENTS/LOADING'}
}

export const setUserDocuments = data => {
  return {type: 'USER_DOCUMENTS/SET', data}
  // return {type: 'USER_DOCUMENTS/SET', data: mock}
}

export const loadUserDocuments = id => {
  return async (dispatch, getState) => {
    dispatch(loadingUserDocuments())
    try {
      const response = await callAPI('profile/cases/')
      dispatch(setUserDocuments(response.data))
    } catch (error) {
      dispatch(setUserDocuments([]))
    }
  }
}
