import React from 'react';
import {withRouter} from 'react-router-dom';
import Layout from '../../components/Layout';
import SendEmailForm from './SendMailForm';
import './styles.scss';

const Signed = ({match}) => {
    const {id} = match.params;
    return (
        <div className="signed">
            <div className="signed__bg" />
            <div className="signed__layer_bg" />
            <Layout isAutoHeight={true} isFooterHidden={true}>
                <div className="signed_form_position">
                    <div className="send_form__frame" />
                    <SendEmailForm id={id} />
                </div>
            </Layout>
        </div>
    )
}

export default Signed;
