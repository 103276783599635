import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import NewDocumentForm from '../../../components/Forms/NewDocumentForm';
import Figure from "../../../components/Figure/Figure";
import Layout from '../../../components/Layout';
import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import './styles.scss';

const NewCase = ({partner, dispatch}) => {
    const history = useHistory()
    const back = () => history.push('/case')

    return (
        <Layout isAutoHeight={true}>
            <div className="PageAddDocument">
                <Figure>
                    <div className="PageAddDocument_title">
                        Загрузка Документа
                        <Close style={{cursor: 'pointer'}} onClick={back}/>
                    </div>
                    <div className="PageAddDocument_content">
                        <p className="PageAddDocument_subtitle">Ссылка на PDF документ</p>
                        <NewDocumentForm/>
                    </div>
                </Figure>
            </div>
        </Layout>
    )
}

export default NewCase;
