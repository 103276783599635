import React, {useState, memo} from "react";
import './styles.scss';
import Icon from '../../../components/Icon/Icon'
// import Path from '../../../assets/icons/Path.svg';
import CheckBlue from '../../../assets/img/check-blue.png';
import classNames from "classnames/bind";
import TYPES from '../../../../../store/customer.types'

const StepFirst = ({setLegalForm, legalForm}) => {
    const onClick = (typeLegal) => {
        setLegalForm(typeLegal);
    }
    return (
        <>
            <div className="first_step">
                <div className="first_step__title">
                    Выберите тип предпринимательства
                </div>
                <div className="first_step__notice">
                    И нажмите “Продолжить”
                </div>
                <div className="first_step__items">
                    <div
                        className={classNames('first_step__item ', {
                            'first_step__item--active': (legalForm === TYPES.TYPE_PERSON)
                        })}
                        onClick={() => onClick(TYPES.TYPE_PERSON)}
                    >
                        <div className="first_step__item_inner">
                            <img src={CheckBlue} alt=""/>&nbsp;&nbsp;
                        </div>
                        Физическое лицо
                    </div>
                    <div
                        className={classNames('first_step__item ', {
                            'first_step__item--active': (legalForm === TYPES.TYPE_ENTERPRENEUR)
                        })}
                        onClick={() => onClick(TYPES.TYPE_ENTERPRENEUR)}
                    >
                        <div className="first_step__item_inner">
                            <img src={CheckBlue} alt=""/>&nbsp;&nbsp;
                        </div>
                        ИП
                    </div>
                    <div
                        className={classNames('first_step__item ', {
                            'first_step__item--active': (legalForm === TYPES.TYPE_ORGANIZATION)
                        })}
                        onClick={() => onClick(TYPES.TYPE_ORGANIZATION)}
                    >
                        <div className="first_step__item_inner">
                            <img src={CheckBlue} alt=""/>&nbsp;&nbsp;
                        </div>
                        Юридическое лицо
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(StepFirst);
