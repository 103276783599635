import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from 'react-dropzone';
import './styles.scss';
import File from '../../../../assets/img/file.png';
import FileMin from '../../../../assets/img/file-min.png';
import Delete from '../../../../assets/img/delete.png';

const Dropzone = (
    {
        formatText,
        emitFile
    }) => {
    const [isUpload, setIsUpload] = useState(false);
    const [file, setFile] = useState();
    const onDrop = useCallback(acceptedFiles => {
        setIsUpload(true);
        setFile(acceptedFiles[0]);
        emitFile(acceptedFiles);
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})
    const remove = () => {
        setIsUpload(false);
        setFile({});
        emitFile([])
    }
    return (
        <>
            {!isUpload
                ? (
                    <div className="dropzone" {...getRootProps()}>
                        <div className="dropzone__wrap">
                            <input {...getInputProps()} />
                            <div className="dropzone__icon">
                                <img src={File} />
                            </div>
                            <div className="dropzone__block">
                                <div className="dropzone__title">
                                    Перетяните файл в это поле
                                </div>
                                <div className="dropzone__text">
                                    Или кликните, чтобы открыть окно загрузки
                                </div>
                                <div className="dropzone__format">
                                    {formatText}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="dropzone" {...getRootProps()}>
                        <div className="dropzone__file">
                            <div className="dropzone__file_icon">
                                <img src={FileMin} alt=""/>
                            </div>
                            <div className="dropzone__file_block">
                                <div>
                                    <div className="dropzone__file_name">
                                        {file?.name}
                                    </div>
                                    <div className="dropzone__file_size">
                                        {(file?.size/1000000).toFixed(2)} Mb
                                    </div>
                                </div>
                                <div className="dropzone__file_delete" onClick={remove}>
                                    <img src={Delete} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    )
}

export default Dropzone;
