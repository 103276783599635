import React from 'react'
// import Documents from '../../components/Document/Documents/'
import Documents from '../../components/Document/Documents'
import './PageDocuments.scss'

export default function () {
    return (
        <div className="PageDocuments">
            <div className="PageDocuments__bg" />
            <Documents />
        </div>
    )
}
