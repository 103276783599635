import React from 'react';
// bg-signed-form
// arrows-down
import SendEmail from '../../../components/Forms/SendEmail';
import Copy from '../../../assets/img/copy-link-white.png';
import {API_DOMAIN} from "../../../../../utils/const";
import './styles.scss';

const SendEmailForm = ({id}) => {
    async function copyLink () {
        await navigator.clipboard.writeText(`https://beta.sign.legium.io/case/${id}`);
        // await navigator.clipboard.writeText(`${API_DOMAIN}/case/${id}`);
        // setCopied(true)
        // setTimeout(() => setCopied(false), 2000)
    }
    return (
        <div className="send_form">

            <div className="send_form__close"></div>
            <div className="send_form__title">
                Документ успешно подписан
            </div>
            <div className="send_form__row">
                <div className="send_form__description">
                    Отправьте ссылку контрагенту
                </div>
                <div className="send_form__block">
                    <div className="send_form__copy" onClick={copyLink}>
                        <img src={Copy} />
                    </div>
                    <SendEmail id={id} isSmall={true} />
                </div>
            </div>
        </div>
    )
}

export default SendEmailForm;
