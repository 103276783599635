import React, {useEffect, useState} from "react";
// import Input from '../../../Input';

import {ReactComponent as SearchIcon} from '../../../../assets/icons/search.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/icons/close.svg';
import {ReactComponent as CloseTagIcon} from '../../../../assets/icons/close-small-tag.svg';

import './styles.scss';

const Search = (
    {
        onClose,
        isOpen,
        setQuery,
        data
    }) => {
    const [value, setValue] = useState('');
    const [tags, setTags] = useState([]);
    const filterData = () => {
        if (value.length) {
            setTags([...tags, value]);
            setValue('');
        }
    }
    const handleEnter = ({key}) => {
        if (key === "Enter") {
            filterData();
        }
    }
    const removeTag = (tag) => {
        const newTags = tags.filter((item) => item !== tag);
        setTags([...newTags]);
    }
    useEffect(() => {
        if (!tags.length) setQuery(data);
        if (!!tags.length) {
            const result = data.filter((item) => (
                item.name.toLowerCase().includes(tags.map(tag => tag.toLowerCase())) ||
                item.signatures?.[0]?.toLowerCase().includes(tags.map(tag => tag.toLowerCase())) ||
                item.signatures?.[1]?.toLowerCase().includes(tags.map(tag => tag.toLowerCase()))
            ));
            setQuery(result);
        }
    }, [tags]);

    return (
        <div className="search">
            {isOpen && (
                <div className="search__wrapper">
                    <div
                        className="search__search"
                        onClick={filterData}
                    >
                        <SearchIcon />
                    </div>
                    <div className="search__wrap">
                        <input
                            className="search__input"
                            onChange={({target}) => setValue(target.value)}
                            value={value}
                            onKeyDown={handleEnter}
                        />
                        <div className="search__close" onClick={() => {
                            onClose();
                            setTags([]);
                            setValue('');
                        }}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>
            )}
            <div className="search__tags">
                {!!tags.length && tags.map((tag, index) => (
                    <div className="search__tag" key={index}>
                        {tag}
                        <div className="search__tag_close" onClick={() => removeTag(tag)}>
                            <CloseTagIcon />
                        </div>
                    </div>
                ))}
                {tags.length > 1 && (
                    <div className="search__tag">
                        очистить
                        <div className="search__tag_close" onClick={() => setTags([])}>
                            <CloseTagIcon />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Search;
