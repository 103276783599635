import React, {useState, useEffect, useRef} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';

import {ReactComponent as CloseDownIcon} from "../../assets/icons/close_down.svg";

import './style.scss';

const ModalAnimate = (
    {
        children,
        isOpen,
        onRequestClose
    }) => {
    const [isShow, setIsShow] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);

    useEffect(() => {
        let timerId;
        let timer;
        if (isOpen) {
            setIsShow(true);
            timer = setTimeout(() => {
                setIsShowModal(true);
            }, 0);
        } else {
            timerId = setTimeout(() => {
                setIsShow(false);
            }, 100);
        }
        return () => {
            clearTimeout(timerId);
            clearTimeout(timer);
        }
    }, [isOpen])
    const close = () => {
        setIsShowModal(false);
        onRequestClose();
    }

    if (!isShow) return null;
    return createPortal(
        <div className="overlay">
            <div className={classNames('modal_animate ', {
                'modal_animate--show': isShowModal
            })}>
                {children}
                <div className="modal_animate__close" onClick={close}>
                    <CloseDownIcon />
                </div>
            </div>
        </div>,
        document.getElementById('root')
    )

}

export default ModalAnimate;
