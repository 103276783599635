import React, {useEffect, useState} from "react";
import classNames from "classnames/bind";
import Tab from './Tab';
import {STEPS} from '../../../../../utils/const';
import './styles.scss';

import ArrowNex from '../../../assets/img/arrow-next.png';
import ArrowPrev from '../../../assets/img/arrow-prev.png';

const POSITIONS = {
    left: 0,
    center: 1,
    right: 2
}

const TabsHeader = (
    {
        firstTabTitle,
        thirdTabTitle,
        setStep,
        step,
        children,
        isValidFirstStep,
        isValidSecondStep,
        isValidThirdStep,
        submit,
        isPersonal,
        innError
    }) => {
    const [firstStepTitle, setFirstStepTitle] = useState('Определение типа <br /> предпринемателя');
    const [secondStepTitle, setSecondStepTitle] = useState('Загрузка дополнительных <br /> данных');
    const [thirdStepTitle, setThirdStepTitle] = useState('Подтверждение <br /> регистрации');
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [position, setPosition] = useState(0);

    useEffect(() => {
        setFirstStepTitle(firstTabTitle);
    }, [firstTabTitle]);
    useEffect(() => {
        if (step === STEPS.second) setSecondStepTitle('Добавление <br /> ИНН и email адреса');
    }, [step]);
    useEffect(() => {
        isPersonal
            ? setThirdStepTitle('Загрузка копии <br /> паспорта')
            : setThirdStepTitle('Реквизиты и подтверждение <br /> регистрации');
    }, [thirdTabTitle, isPersonal]);

    function handleTouchStart(event) {
        if (event.targetTouches?.[0]) {
            setTouchStart(event.targetTouches[0].clientX);
        }
    }

    function handleTouchMove(event) {
        if (event.targetTouches?.[0]) {
            setTouchEnd(event.targetTouches[0].clientX);
        }
    }
    function handleTouchEnd() {
        if (touchStart - touchEnd > 60) {
            if (position !== 2) setPosition(position + 1);
        }

        if (touchStart - touchEnd < -60) {
            if (position !== 0) setPosition(position - 1);
        }
    }

    return (
        <>
            <div
                className={classNames('tabs__switch_wrap', {
                    'tabs__switch_wrap--left': (position === POSITIONS.left),
                    'tabs__switch_wrap--center': (position === POSITIONS.center),
                    'tabs__switch_wrap--right': (position === POSITIONS.right)
                })}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="tabs__tabs">
                    <Tab
                        title={firstStepTitle}
                        number={1}
                        onEdit={() => {
                            setStep(STEPS.first);
                            setPosition(0);
                        }}
                        onNext={() => {
                            setStep(STEPS.second);
                            setPosition(1);
                        }}
                        isActive={step === STEPS.first}
                        isValid={isValidFirstStep}
                    />
                    <Tab
                        title={secondStepTitle}
                        number={2}
                        onEdit={() => {
                            setStep(STEPS.second);
                            setPosition(1);
                        }}
                        onNext={() => {
                            setStep(STEPS.third);
                            setPosition(2);
                        }}
                        isActive={step === STEPS.second}
                        isValid={isValidSecondStep}
                        innError={innError}
                    />
                    <Tab
                        title={thirdStepTitle}
                        number={3}
                        onEdit={() => {
                            setStep(STEPS.third);
                            setPosition(2)
                        }}
                        onNext={() => {
                            submit();
                        }}
                        isActive={step === STEPS.third}
                        isValid={isValidThirdStep}
                    />
                </div>
            </div>

            {children}

            <div className="tabs__controllers">
                {step === STEPS.first && (
                    <>
                        {isValidFirstStep ? (
                            <div className="tabs__controllers_single tabs__controllers_single--relative">
                                <div className="tabs__controllers_button">
                                    Регистрация через Банк Точка
                                </div>
                                <div
                                    className="tabs__controller"
                                    onClick={() => {
                                        setStep(STEPS.second);
                                        setPosition(1);
                                    }}
                                >
                                    Продолжить &nbsp;
                                    <img src={ArrowNex} alt=""/>
                                </div>
                            </div>
                        ) : (
                            <div className="tabs__controllers_single tabs__controllers_single--relative">
                                <div className="tabs__controllers_button">
                                    Регистрация через Банк Точка
                                </div>
                                <div
                                    className="tabs__controller tabs__controller--disabled"
                                >
                                    Продолжить &nbsp;
                                    <img src={ArrowNex} alt=""/>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {step === STEPS.second && (
                    <>
                        <div
                            className="tabs__controller"
                            onClick={() => {
                                setStep(STEPS.first);
                                setPosition(0);
                            }}
                        >
                            <img src={ArrowPrev} alt=""/> &nbsp;
                            Предыдущий шаг
                        </div>
                        {isValidSecondStep ? (
                            <div
                                className="tabs__controller"
                                onClick={() => {
                                    setStep(STEPS.third);
                                    setPosition(2);
                                    // submit();
                                }}
                            >
                                Продолжить &nbsp;
                                <img src={ArrowNex} alt=""/>
                            </div>
                        ) : (
                            <div
                                className="tabs__controller tabs__controller--disabled"
                            >
                                Продолжить &nbsp;
                                <img src={ArrowNex} alt=""/>
                            </div>
                        )}
                    </>
                )}
                {step === STEPS.third && (
                    <>
                        <div
                            className="tabs__controller"
                            onClick={() => {
                                setStep(STEPS.second);
                                setPosition(1);
                            }}
                        >
                            <img src={ArrowPrev} alt=""/>&nbsp;
                            Предыдущий шаг
                        </div>
                        {isValidThirdStep ? (
                            <div
                                className="tabs__controller"
                                onClick={submit}
                            >
                                Продолжить &nbsp;
                                <img src={ArrowNex} alt=""/>
                            </div>
                        ) : (
                            <div
                                className="tabs__controller  tabs__controller--disabled"
                            >
                                Продолжить &nbsp;
                                <img src={ArrowNex} alt=""/>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default TabsHeader;
