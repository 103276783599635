import React, {useEffect} from "react";
import {connect} from 'react-redux';
import Layout from '../../components/Layout';
import TheFrame from "../../components/Frame";
import "./styles.scss";
import {ReactComponent as Avatar} from "../../assets/icons/avatar-min.svg";

const PersonalZone = ({
  profile,
}) => {
    return (
        <Layout isAutoHeight={true}>
            <div className="PersonalZone-bg">
                <TheFrame isCard={true} className="PersonalZoneWrap">
                    <div className="PersonalZone">
                        <div className="PersonalZone_header">Личный кабинет</div>
                        <div className="PersonalZone_info">
                            <div className="PersonalZone_info__title">
                                Пользователь
                            </div>
                            <div className="PersonalZone_info__user">
                                <div className="PersonalZone_info__avatar">
                                    <Avatar />
                                </div>
                                <div className="PersonalZone_info__block">
                                    <div className="PersonalZone_info__block_title">
                                        {profile?.username}
                                    </div>
                                    <div className="PersonalZone_info__sub_title">
                                        {profile?.legal_name}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="PersonalZone_info">
                            <div className="PersonalZone_info__block_title">E-mail</div>
                            <div className="PersonalZone_info__email">{profile?.email}</div>
                        </div>
                        {/*<div className="PersonalZone_info">*/}

                        {/*</div>*/}
                        {/*<div className="PersonalZone_info">*/}

                        {/*</div>*/}
                    </div>
                </TheFrame>
            </div>
        </Layout>
    )
}

export default connect(
    state => ({profile: state.auth.profile}),
    null
)(PersonalZone);
