import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import Spinner from '../Spinner/Spinner'

const AuthorizedRoute = ({component: Component, auth, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.loading) {
          return <Spinner />
        }

        if (auth.token !== null) {
          if (auth.profile !== null) {
            if (auth.profile.status === 'APPROVED') {
              return <Component {...props} />
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/profile',
                  }}
                />
              )
            }
          }
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location},
            }}
          />
        )
      }}
    />
  )
}

export default connect(
  state => ({auth: state.auth}),
  null,
)(AuthorizedRoute)
