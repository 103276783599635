import React from 'react';
import classNames from "classnames/bind";
import './frame.scss';

export default function ({ className = '', children = null, isCard = false }) {

    return <div className={classNames(`Frame ${className}`, {
        'Frame--card': isCard
    })}>
        {children}
    </div>
}
