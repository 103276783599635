import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import PrivateRoute from './components/Route/Private'
import AuthorizedRoute from './components/Route/Authorized'
import {loadProfile} from './store/actions/auth'
import {PROJECTS} from './utils/const'

//  временное решение
// import './App.scss'

export let routes = [];
PROJECTS.forEach((PROJECT) => {
  if (process.env.REACT_APP_ENV === PROJECT.name) {
    routes = [...PROJECT.routes]
  }
})

export default function App() {
  const dispatch = useDispatch();
  dispatch(loadProfile());

  return (
  <div>
    <Router>
      <Switch>
        {/*<PrivateRoute exact path="/profile" component={Profile} />*/}
        {/*<AuthorizedRoute exact path="/case/new/" component={Upload} />*/}
        {/*<Route path="/case/:id" component={Case} />*/}
        {/*<Route exact path="/login" component={Login} />*/}
        {/*<Redirect exact from="/" to="/profile" />*/}
        {/*<Route path="*" component={NoMatch} />*/}
        {routes.map((route, index) => {
          if (route.isPrivate) {
            return (
              <PrivateRoute
                key={index}
                exact={route.isExact}
                path={route.path}
                component={route.component}
              />
            )
          }
          if (route.redirect) {
            return (
              <Redirect
                key={index}
                exact={route.isExact}
                from={route.redirect.from}
                to={route.redirect.to}
                component={route.component}
              />
            )
          }
          if (route.isAuthorizedRoute) {
            return (
              <AuthorizedRoute
                key={index}
                exact={route.isExact}
                path={route.path}
                component={route.component}
              />
            )
          }
          return (
            <Route
              key={index}
              exact={route.isExact}
              path={route.path}
              component={route.component}
            />
          )
        })}
      </Switch>
    </Router>
  </div>
  )
}
