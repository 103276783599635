import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { withRouter } from 'react-router';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Spinner from "../../../components/Spinner/Spinner";
import LayoutPage from '../../../components/LayoutPage';
// import Layout from '../../../components/Layout';
import Controls from '../../../components/Controls';
import {API_DOMAIN} from "../../../../../utils/const";
import Check from "../../../assets/img/Path.png";
import CheckDouble from "../../../assets/img/Union.png";
import './style.scss';

// import {loadDocument} from "../../../../../store/actions";
import {connect} from "react-redux";

const Preview = ({documents, dispatch, match}) => {
    const [numPages, setNumPages] = useState(null);
    const [documentWidth, setDocumentWidth] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
    const history = useHistory();
    const {id} = match.params;
    const document = documents && documents[id]?.data;
    // console.log('document: ', document);
    // const { meta, url, hashsum, is_signed_by_user, signatures, public_id } = document;

    useEffect(() => {
        function resize () {
            setWindowWidth(window.innerWidth)
            setDocumentWidth(windowWidth * (windowWidth < 1024 ? 0.8 : 0.4))
        }

        resize()
        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [window.innerWidth])

    useEffect(() => {
        return () => {
            setIsDocumentLoaded(false);
        }
    }, [])

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
        setIsDocumentLoaded(true)
    }

    if (!document && !document?.status) return null;

    const documentPath = new URL(document.url).pathname;
    const documentUrl = API_DOMAIN + documentPath;

    return (
        <LayoutPage>
            <div className="preview">
                {isDocumentLoaded && (
                    <div className="preview__signed_icon">
                        {document.signed ? (
                            <img src={CheckDouble} alt=""/>
                        ) : (
                            <img src={Check} alt=""/>
                        )}
                    </div>
                )}
                <Document
                    className=""
                    file={documentUrl}
                    loading={Spinner}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {
                        Array.from(
                            new Array(numPages), (el, index) => (
                                <div className="preview__card">
                                    <Page
                                        className="DocumentPreview_documentPage"
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        renderAnnotationLayer={false}
                                        width={documentWidth}
                                    />
                                </div>
                            ),
                        )
                    }
                </Document>
                <div className="preview__controls">
                    <Controls
                        documentUrl={documentUrl}
                        isSigned={document.signed}
                        isSignedByUser={document.is_signed_by_user}
                        redirectTo={() => history.push(`/detail/${id}`)}
                        id={id}
                    />
                </div>
            </div>
        </LayoutPage>
    )
}

export default connect(
    state => ({
        documents: state.documents,
        auth: state.auth,
    }),
    null,
)(withRouter(Preview));
