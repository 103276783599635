import React, {Fragment, useEffect} from 'react'
import NoMatch from '../Error/NoMatch'
import {loadDocument} from '../../../../store/actions'
import {connect} from 'react-redux'
import DocumentDetail from './Details'
import DocumentError from '../Error/Error'
import DocumentInit from './Init'
import Spinner from '../../components/Spinner/Spinner'
import Preview from './Preview';
import Layout from '../../components/Layout';

const statusToComponent = {
    INIT: DocumentInit,
    PROCESSING: DocumentInit,
    ERROR: DocumentError,
    SUCCESS: Preview,
    // SUCCESS: DocumentDetail,
}

const Case = ({match, dispatch, documents, auth}) => {
    const {id} = match.params
    const doc = documents && documents[id]
    let StatusComponent = null
    // useEffect()
    useEffect(() => {
        dispatch(loadDocument(id))
    }, [id, auth.token])

    if (doc && doc.status !== null) {

        if (doc.status === 'NOT_FOUND') {
            return <NoMatch />
        }

        if (doc.status === 'ERROR') {
            return <DocumentError text="При обработке файла произошла ошибка. Необходимый формат документа - pdf."/>
        }
        StatusComponent = statusToComponent[doc.status]

        return StatusComponent
            ? (
                <Layout isAutoHeight={true} isFooterHidden={doc.status === 'SUCCESS'}>
                    <StatusComponent id={id} document={doc.data} dispatch={dispatch} />
                </Layout>
            )
            : (
                <Layout isAutoHeight={true}>
                    <Spinner />
                </Layout>
            )
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
        </div>
    )
}

export default connect(
    state => ({
        documents: state.documents,
        auth: state.auth,
    }),
    null,
)(Case);
