import React, {useCallback, useEffect, useState} from "react";
import './styles.scss';
import File from '../../../../assets/img/file.png';
import FileMin from '../../../../assets/img/file-min.png';
import DownloadFile from '../../../../assets/img/download.png';

const Download = (
    {
        downloadLink
    }) => {
    return (
        <>
            <div className="download">
                <a className="download__wrap-link" href={downloadLink} target="_blank">
                    <div className="download__file">
                        <div className="download__file_icon">
                            <img src={FileMin} alt=""/>
                        </div>
                        <div className="download__file_block">
                            <div>
                                <div className="download__file_name">
                                    Скачать реквизиты
                                </div>
                                <div className="download__file_size">
                                    PDF    1 Mb
                                </div>
                            </div>
                            <div className="download__file_delete">
                                <img src={DownloadFile} alt=""/>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </>
    )
}

export default Download;
