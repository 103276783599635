import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import Button from '../../Button'
import DocumentsList from '../DocumentsList'
import Input from '../../Input'
import DocumentsPlaceholder from '../DocumentsPlaceholder';
import Search from './Seach/index';
// import Select from '../../components/Select'
// import Tabs from '../../Tabs/Tabs'
import Empty from '../DocumentsEmpty'
// import TabItem from '../../Tabs/TabItem'
import {formatDate} from '../../../../../utils/date'
import {loadUserDocuments as loadUserDocumentsAction} from '../../../../../store/actions/userDocuments'
import './styles.scss'
import {ReactComponent as Upload} from '../../../assets/icons/upload.svg';
import {ReactComponent as SearchIcon} from '../../../assets/icons/search.svg';

const Documents = ({documents, loadUserDocuments, loading, partner, proxy}) => {
    const history = useHistory();
    const [query, setQuery] = useState([]);
    const [visibleUnsigned, setVisibleUnsigned] = useState(5);
    const [visibleSigned, setVisibleSigned] = useState(5);
    const [queryVisible, setQueryVisible] = useState(20);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const newDocument = () => history.push('/case/new')

    useEffect(() => {
        loadUserDocuments();
    }, [])

    if (loading) {
        return <DocumentsPlaceholder />
    } else if (!documents.length) {
        return <Empty />
    }

    const restriction =
        'Вы не уполномочены подписывать документы электронной подписью. Вы можете только добавлять документы на подпись.'
    let alertText
    if (partner === 'MRG') {
        if (proxy) {
            const proxyDate = new Date(proxy.proxy_data)
            const proxyDeadline = new Date(proxy.proxy_deadline)
            const currentDate = new Date()
            alertText =
                currentDate >= proxyDate && currentDate <= proxyDeadline
                    ? `Вы уполномочены подписывать документы электронной подписью 
                 на основании доверенности №${proxy.proxy_number} от ${formatDate(proxyDate, true)}`
                    : restriction
        } else {
            alertText = restriction
        }
    }

    const filteredUnsignedDocuments = documents.filter(doc => doc.signed === false);
    const filteredSignedDocuments = documents.filter(doc => doc.signed === true);
    const filteredUnsignedDocumentsCute = filteredUnsignedDocuments.slice(0, visibleUnsigned);
    const filteredSignedDocumentsCute = filteredSignedDocuments.slice(0, visibleSigned);

    const loadMoreUnsigned = () => {
        setVisibleUnsigned((prevState) => (prevState + 5))
    }
    const loadMoreSigned = () => {
        setVisibleSigned((prevState) => (prevState + 5))
    }
    const loadMoreQuery = () => {
        setQueryVisible((prevState) => (prevState + 5))
    }
    const getCount = (VisibleLength, fullLength) => {
        const remainder = fullLength - VisibleLength;
        if (remainder === 1) {
            return `остальной ${remainder} документ`;
        }
        if (remainder < 5 && remainder > 1) {
            return `остальные ${remainder} документа`;
        }
        return 'остальные 5 документов';
    }

    return (
        <div className="Documents">
            <div className="Documents_frame">
                <div className="Documents_filters">
                    {
                        !isSearchOpen && (
                            <div className="Documents_filters_wrap">
                                <b className="Documents_title">Документы</b>
                                {/* <Select mode="radio" options={filters}></Select> */}
                                <div className="Documents_filter_side">
                                    <span className="Documents_icon_first">
                                        <Upload onClick={newDocument}/>
                                    </span>
                                    <span
                                        className="Documents_icon_last"
                                        onClick={() => setIsSearchOpen(true)}
                                    >
                                        <SearchIcon />
                                    </span>
                                    {/*<Input className="Documents_search" value={query} onChange={setQuery} />*/}
                                </div>
                            </div>
                        )
                    }
                    <Search
                        onClose={() => setIsSearchOpen(false)}
                        setQuery={(data) => setQuery(data)}
                        isOpen={isSearchOpen}
                        data={documents}
                    />
                </div>
                {isSearchOpen && (
                    <>
                        <div className="Documents_items">
                            Результаты поиска
                            <span className="Documents_count">
                                {query.length}
                            </span>
                        </div>

                        <div className="Documents_signed">
                            <DocumentsList documents={query} />
                        </div>
                        <div className="Documents_more">
                            {queryVisible < query.length && (
                                <span onClick={loadMoreQuery}>Подгрузить остальные {getCount(queryVisible, query.length)} документов</span>
                            )}
                        </div>
                    </>
                )}
                {!isSearchOpen && (
                    <>
                        {!documents.length ? (
                            <div>Нет элементов для отображения</div>
                        ) : (
                            <>
                                {filteredUnsignedDocuments &&
                                <>
                                    <div className="Documents_items">
                                        На подписании
                                        <span className="Documents_count">
                                        {filteredUnsignedDocuments.length}
                                    </span>
                                    </div>

                                    <div className="Documents_signed">
                                        <DocumentsList documents={filteredUnsignedDocumentsCute} />
                                    </div>
                                    <div className="Documents_more">
                                        {visibleUnsigned < filteredUnsignedDocuments.length && (
                                            <span onClick={loadMoreUnsigned}>Подгрузить {getCount(visibleUnsigned, filteredUnsignedDocuments.length)}</span>
                                        )}
                                    </div>
                                </>
                                }
                                {filteredSignedDocuments &&
                                <>
                                    <div className="Documents_items">Подписаны<span className="Documents_count">
                                        {filteredSignedDocuments.length}</span>
                                    </div>
                                    <div className="Documents_unsigned">
                                        <DocumentsList documents={filteredSignedDocumentsCute} />
                                    </div>
                                    <div className="Documents_more">
                                        {visibleSigned < filteredSignedDocuments.length && (
                                            <span onClick={loadMoreSigned}>Подгрузить {getCount(visibleSigned, filteredSignedDocuments.length)}</span>
                                        )}
                                    </div>
                                </>
                                }
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default connect(
    (state) => ({
        documents: state.userDocuments.data,
        loading: state.userDocuments.loading,
        // partner: state.auth.profile.partner,
        // proxy: state.auth.profile.proxy,
    }),
    (dispatch) => ({
        loadUserDocuments: () => dispatch(loadUserDocumentsAction()),
    }),
)(Documents)
