import React, {useRef, useState, useEffect} from 'react';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable'
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as ConfirmIcon} from "../../../assets/icons/confirm.svg";
import {loadProfile} from "../../../../../store/actions/auth";

const TitleEditable = ({
   children,
   emitData,
   profile,
   dispatch,
   isSigned
}) => {
    const [value, setValue] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const text = useRef('');

    const handleChange = evt => {
        text.current = evt.target.value;
    };

    const handleBlur = () => {
        if (!text.current.length) {
            text.current = children;
        }
        emitData?.(text.current);
        setIsEditing(false);
    };
    const editTitle = () => {
        dispatch(loadProfile());
        if (profile) {
            setIsEditing(true);
        }
    }
    useEffect(() => {
        if (!!children) {
            text.current = children;
            setValue(children);
        }
    }, [children])
    useEffect(() => {
        setValue(text.current);
    }, [text.current])

    return (
        <div className="detail__title_block">
            {profile && !isSigned && isEditing ? (
                <ContentEditable
                    className="detail__title"
                    html={value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
            ) : (
                <div className="detail__title">
                    {children}
                </div>
            )}
            <div className="detail__title_edit">
                {profile && !isSigned && (
                    <>
                        {!isEditing ? (
                            <EditIcon
                                onClick={editTitle}
                            />
                        ) : (
                            <ConfirmIcon />
                        )}
                    </>
                )}
            </div>
        </div>

    )
}

export default connect(
    (state) => ({
        profile: state.auth.profile,
    })
)(TitleEditable);
