import React, {useState} from 'react';
import { useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom';
import {removeDocument, getDataFromURL} from "../../../../store/actions/documents"
import classNames from "classnames/bind";

import Delete from '../../assets/img/delete.png';
import CopyLink from '../../assets/img/copy-link.png';
import Sign from '../../assets/img/sign.png';
import Signed from '../../assets/img/signed.png';
import Mail from '../../assets/img/mail.png';
import DocInfo from '../../assets/img/doc-info.png';
import Download from '../../assets/img/download.png';

import DocumentSignForm from '../Forms/DocumentSignForm';
import SendEmail from '../Forms/SendEmail';
import Notification from '../Notification';
import ModalAnimate from "../ModalAnimate";

import './styles.scss';
import Modal from "../Modal/Modal";

const Notifications = {
    SIGN: 'SIGN',
    EMAIL: 'EMAIL',
    DOC: 'DOC',
    COPY: 'COPY',
    DOWNLOAD: 'DOWNLOAD'
}

const Controls = (
    {
        documentUrl,
        isSigned,
        isSignedByUser,
        id,
        redirectTo
    }) => {
    const history = useHistory();
    const [inited, setInited] = useState(false);
    const [isShowEmailForm, setIsShowEmailForm] = useState(false);
    const dispatch = useDispatch();
    const [notification, setNotification] = useState(Notifications.SIGN);
    const [copied, setCopied] = useState(false);

    async function copyLink () {
        await navigator.clipboard.writeText(window.location.href)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const removeHandle = async () => {
        await dispatch(removeDocument(id));
        await history.push('/profile');
    }
    return (
        <div className="controls">
            {!isShowEmailForm && (
                <>
                    {!isSigned && (
                        <div className="controls__delete" onClick={removeHandle}>
                        <img src={Delete} alt=""/>
                        </div>
                    )}
                    <div className={classNames("controls__buttons", {
                        "controls__buttons--bg_wight": (!isSigned && isSignedByUser)
                    })}>
                        {!isSigned && !isSignedByUser && (
                            <>
                                <div className="controls__buttons_left">
                                    <div className="controls__button">
                                        <a href={documentUrl} target="_blank">
                                            <img src={Download} alt=""/>
                                        </a>
                                        <Notification
                                            style={{bottom: '47px', left: '-74px'}}
                                            isShow={notification === Notifications.DOWNLOAD}
                                            onClose={() => setNotification('')}
                                            onChange={() => setNotification(Notifications.COPY)}
                                        >
                                            Чтобы скачать документ, нажмите на эту кнопку
                                        </Notification>
                                    </div>
                                    <div className="controls__button" onClick={copyLink}>
                                        <img src={CopyLink} alt=""/>
                                        <Notification
                                            style={{bottom: '57px', left: '-71px'}}
                                            isShow={notification === Notifications.COPY}
                                            onClose={() => setNotification('')}
                                            onChange={() => setNotification(Notifications.EMAIL)}
                                        >
                                            Чтобы скопировать ссылку на подписание документа, нажмите на эту кнопку
                                        </Notification>
                                        <Notification
                                            style={{bottom: '57px', left: '-71px'}}
                                            isShow={copied}
                                            onClose={() => setCopied(false)}
                                            isEnd={true}
                                        >
                                            Ссылка на этот документ скопирована, отправьте ее контрагенту
                                        </Notification>
                                    </div>
                                    <div
                                        className="controls__button"
                                        onClick={() => setIsShowEmailForm(true)}
                                    >
                                        <img src={Mail} alt=""/>
                                    </div>
                                    <Notification
                                        style={{bottom: '57px', left: '26px'}}
                                        isShow={notification === Notifications.EMAIL}
                                        onClose={() => setNotification('')}
                                        isEnd={true}
                                    >
                                        Чтобы отправить ссылку на подписание документа по почте, нажмите на эту кнопку
                                    </Notification>
                                </div>
                                <div className="controls__button controls__button--sign">
                                    <div className="controls__sign">
                                        <div className="controls__sign_button" onClick={() => setInited(true)}>
                                            <img src={Sign} alt=""/>
                                        </div>
                                    </div>
                                    <Notification
                                        isShow={notification === Notifications.SIGN}
                                        onClose={() => setNotification('')}
                                        onChange={() => setNotification(Notifications.DOC)}
                                    >
                                        Чтобы подписать документ, нажмите на эту кнопку
                                    </Notification>
                                </div>
                            </>
                        )}
                        {!isSigned && isSignedByUser && (
                            <>
                                <div className="controls__buttons_left">
                                    <div className="controls__button">
                                        <a href={documentUrl} target="_blank">
                                            <img src={Download} alt=""/>
                                        </a>
                                        <Notification
                                            style={{bottom: '47px', left: '-74px'}}
                                            isShow={notification === Notifications.DOWNLOAD}
                                            onClose={() => setNotification('')}
                                            onChange={() => setNotification(Notifications.COPY)}
                                        >
                                            Чтобы скачать документ, нажмите на эту кнопку
                                        </Notification>
                                    </div>
                                    <div className="controls__button" onClick={copyLink}>
                                        <img src={CopyLink} alt=""/>
                                        <Notification
                                            style={{bottom: '57px', left: '-71px'}}
                                            isShow={notification === Notifications.COPY}
                                            onClose={() => setNotification('')}
                                            onChange={() => setNotification(Notifications.EMAIL)}
                                        >
                                            Чтобы скопировать ссылку на подписание документа, нажмите на эту кнопку
                                        </Notification>
                                        <Notification
                                            style={{bottom: '57px', left: '-71px'}}
                                            isShow={copied}
                                            onClose={() => setCopied(false)}
                                            isEnd={true}
                                        >
                                            Ссылка на этот документ скопирована, отправьте ее контрагенту
                                        </Notification>
                                    </div>
                                    <div
                                        className="controls__button"
                                        onClick={() => setIsShowEmailForm(true)}
                                    >
                                        <img src={Mail} alt=""/>
                                    </div>
                                    <Notification
                                        style={{bottom: '57px', left: '26px'}}
                                        isShow={notification === Notifications.EMAIL}
                                        onClose={() => setNotification('')}
                                        isEnd={true}
                                    >
                                        Чтобы отправить ссылку на подписание документа по почте, нажмите на эту кнопку
                                    </Notification>
                                </div>
                            </>
                        )}
                        {isSigned && (
                            <div className="controls__button controls__button--sign">
                                <div className="controls__sign">
                                    <div className="controls__sign_button">
                                        <a href={documentUrl} target="_blank">
                                            <img src={Signed} alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div style={{position: 'relative'}}>
                            <div
                                className="controls__button controls__button--doc"
                                onClick={() => redirectTo()}
                            >
                                <img src={DocInfo} alt=""/>


                            </div>
                            <Notification
                                style={{bottom: '57px'}}
                                isShow={notification === Notifications.DOC}
                                onClose={() => setNotification('')}
                                onChange={() => setNotification(Notifications.DOWNLOAD)}
                            >
                                Чтобы посмотреть, кто подписал документ, нажмите на эту кнопку
                            </Notification>
                        </div>
                    </div>
                </>
            )}
            {/*<Modal isOpen={inited} onRequestClose={() => setInited(false)}>*/}
            {/*    <DocumentSignForm onSigned={() => setInited(false)} demo={true} id={id}/>*/}
            {/*</Modal>*/}
            <ModalAnimate
                isOpen={inited}
                onRequestClose={() => setInited(false)}
            >
                <DocumentSignForm onSigned={() => setInited(false)} demo={true} id={id}/>
            </ModalAnimate>

            {isShowEmailForm && (
                <SendEmail
                    id={id}
                    close={() => setIsShowEmailForm(false)}
                />
            )}
        </div>
    )
}

export default Controls;
