import React, {useEffect} from "react";
import Dropzone from './Dropzone';
import Download from './Download';
import './styles.scss';

const StepThird = (
    {
        setAddress,
        setPhoto,
        setVideo,
        isPersonal,
        setValidThirdStep
    }) => {
    useEffect(() => {
        !isPersonal
            ? setValidThirdStep(true)
            : setValidThirdStep(false);
    }, [isPersonal]);

    return (
        <div className="step_third">
            {isPersonal ? (
                <>
                    <div className="step_third__block">
                        <div className="step_third__title">
                            Загрузите страницу паспорта с фотографией
                        </div>
                        <div className="step_third__wrap">
                            <Dropzone formatText="*.JPEG, *.JPG, *PNG" emitFile={(file) => setAddress(file)} />
                        </div>
                    </div>
                    <div className="step_third__block">
                        <div className="step_third__title">
                            Загрузите страницу паспорта с регистрацией
                        </div>
                        <div className="step_third__wrap">
                            <Dropzone formatText="*.JPEG, *.JPG, *PNG" emitFile={(file) => setPhoto(file)} />
                        </div>
                    </div>
                    <div className="step_third__block step_third__block--video">
                        <div className="step_third__title">
                            Загрузите селфи-видео
                        </div>
                        <div className="step_third__wrap">
                            <Dropzone formatText="*.MPEG3, *.MPEG4, *.MOV" emitFile={(file) => {
                                setVideo(file);
                                setValidThirdStep(true);
                            }} />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="step_third__block">
                        <div className="step_third__title">
                            Регистрация почти окончена
                        </div>
                        <div className="step_third__text">
                            Для идентификации необходимо внести 1 рубль с вашего расчетного счета в банке <b>по реквизитам предоставленным ниже</b>.
                        </div>
                        <div className="step_third__wrap">
                            <Download
                                downloadLink='https://api.legium.io/static/bill.pdf'
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default StepThird;
