import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
// import {Spinner} from 'components'

const PrivateRoute = ({component: Component, auth, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.token !== null) {
          return <Component {...props} />
        }

        if (auth.loading) {
          return null
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location},
            }}
          />
        )
      }}
    />
  )
}

export default connect(
  state => ({auth: state.auth}),
  null,
)(PrivateRoute)
