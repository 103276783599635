import Signin from './pages/Signin';
import Documents from './pages/Document/Documents';
import NewCase from './pages/Document/NewCase';
import NoMatch from './pages/Error/NoMatch';
import Document from './pages/Document/Document';
import Signed from './pages/Signed/index';
import Profile from './pages/Profile/Profile';
import PersonalZone from './pages/PersonalZone/';
// import Preview from "./pages/Document/Preview";
import Detail from "./pages/Document/Details";

export const Routes = [
  {
    path: '/login',
    isExact: true,
    isPrivate: false,
    component: Signin,
  },
  {
    path: '/profile',
    isExact: true,
    isPrivate: true,
    component: Profile,
  },
  {
    path: '/case',
    isExact: true,
    isPrivate: true,
    component: Documents,
  },
  {
    path: '/case/new',
    isExact: true,
    isAuthorizedRoute: true,
    component: NewCase,
  },
  {
    path: '/case/:id',
    isExact: true,
    isPrivate: false,
    component: Document,
  },
  {
    path: '/signed/:id',
    isExact: true,
    isPrivate: false,
    component: Signed,
  },
  // {
  //   path: '/preview/:id',
  //   isExact: true,
  //   isPrivate: false,
  //   component: Preview,
  // },
  {
    path: '/detail/:id',
    isExact: true,
    isPrivate: false,
    component: Detail,
  },
  {
    path: '/profile/personalzone',
    isExact: false,
    isPrivate: true,
    component: PersonalZone,
  },
  {
    path: '*',
    isExact: false,
    isPrivate: false,
    isAuthorizedRoute: true,
    component: NoMatch,
  },
  {
    redirect: {
      from: '/',
      to: '/profile'
    },
    isExact: true,
  }
];

