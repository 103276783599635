import React from 'react'
import Link from '../../components/Link'
import {connect} from 'react-redux'
import {billRequisites} from "../../../../store/other"
import TheFrame from '../../components/Frame'
import TYPES from '../../../../store/customer.types'

import './styles.scss';

const ProfilePending = ({profile}) => {

    const isOfficial = [
        TYPES.TYPE_ORGANIZATION,
        TYPES.TYPE_ENTERPRENEUR
    ].indexOf(profile.legal_form) > -1

    return (
        <div className="page">
            <TheFrame isCard={true}>
                <h1 className="page__title">Регистрация завершена</h1>

                <p className="page__text" style={{marginBottom: 20}}>
                    Мы&nbsp;все проверим и&nbsp;в&nbsp;течение ближайшего времени отправим SMS с&nbsp;подтверждением.
                </p>

                {isOfficial
                    ? <p className="page__text">
                        Не забудьте, что для идентификации необходимо перевести <span
                        style={{fontWeight: 'bold'}}>1₽</span>&nbsp;с&nbsp;вашего расчетного счета в&nbsp;банке
                        по&nbsp;этим реквизитам&nbsp;(
                        <Link target="_blank" color url={billRequisites.pdf}>pdf</Link>
                        )
                    </p>
                    : null
                }
            </TheFrame>
        </div>
    )
}

export default connect(
    state => ({profile: state.auth.profile}),
    null,
)(ProfilePending)
