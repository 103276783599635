import React from 'react'
// import Btn from 'arui-feather/tag-button'
import './Button.scss'

export default function (props) {
    const {
        className = '',
        short = false,
        children,
        ...otherProps
    } = props
    // return <Btn className={`${className} Button ${short ? 'Button__short' : ''}`} {...otherProps}></Btn>
    return (
        <button
            className={`${className} Button ${short ? 'Button__short' : ''}`}
            {...otherProps}
        >
            {children}
        </button>
    )
}
