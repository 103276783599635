import React from 'react'
import './styles.scss'

const LayoutPage = ({children}) => {
    return (
        <div className="layout_page">
            {children}
        </div>
    )
}

export default LayoutPage;
