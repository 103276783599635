import React, {useState, useEffect, useMemo} from 'react'
import {useHistory} from 'react-router-dom';
import Input from '../Input'
// import ReactCodeInput from 'react-code-input'
import CodeInput from '../CodeInput';
import Button from '../Button'
import PhoneInput from '../InputPhone/index'
import {useDispatch,useSelector} from 'react-redux'
import './DocumentSignForm.scss'
import {
    commitLoginSignature,
    requestSignatureCode,
    commitSignature
} from "../../../../store/actions/signature"
import {requestAuthCode, requestAuthToken} from "../../../../store/actions/auth"
import FormField from "../FormField";
import {LOGIN_STEP_PIN} from "./SigninForm";
export const ERR_INCORRECT_PIN = 'Неверный код'
export const ERR_INCORRECT_PHONE = 'Введен неверный номер телефона'


export default function ({ id, demo = false, onSigned }) {
    const history = useHistory();
    const [code, setCode] = useState()
    const currentPhone = useSelector((state) => state.auth.profile && state.auth.profile.username)
    const [phone, setPhone] = useState(currentPhone)
    const [isAuth, setIsAuth] = useState(false)
    const dispatch = useDispatch()
    const [error, setError] = useState(false)
    const [isPinIncorrect, setPinIncorrect] = useState(false)
    const [initialized, setInitialized] = useState(!!phone)
    const validateCode = pin => pin && pin.length === 6
    const isCodeValid = useMemo(() => validateCode(code), [code])

    useEffect(() => {
        if (phone) {
            setIsAuth(true);
            requestSigningDocument()
        }
    }, [])

    function clearError () {
        setError(false)
    }

    useEffect(() => {
        if (isCodeValid) {
            try {
                isAuth
                     ? signAuthorized()
                     : sign();
            } catch (err) {
                setPinIncorrect(true)
            }
        }
    }, [isCodeValid])

    function onPhoneChange(phoneNumber) {
        setPhone(phoneNumber)
        setError(false)
    }

    async function requestCode (e) {
        if (e) e.preventDefault()
        try {
            await dispatch(requestAuthCode(phone))
            setInitialized(true);
            setSeconds(30);
        } catch (err) {
            setError('Неверный формат номера')
        }
    }
    async function requestSigningDocument (e) {
        if (e) e.preventDefault()
        try {
            await dispatch(requestSignatureCode(id))
            setInitialized(true);
            setSeconds(30);
        } catch (err) {

        }
    }

    async function sign (e) {
        e && e.preventDefault()
        try {
            await dispatch(commitLoginSignature(
                id,
                phone,
                code,
                demo
            ))
            onSigned();
            history.push('/profile');
        } catch(err) {
            setError('Неверный код')
        }
    }
    async function signAuthorized (e) {
        e && e.preventDefault()
        try {
            await dispatch(commitSignature(
                id,
                code
            ))
            onSigned();
            history.push(`/signed/${id}`);
        } catch(err) {
            setError('Неверный код')
        }
    }
    async function resendPhone(event) {
        setSeconds(30);
        requestCode(event);
    }

    const [ minutes, setMinutes ] = useState(0);
    const [seconds, setSeconds ] =  useState(30);
    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(30);
                }
            }
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    return (
        <div className="sign">
            <h3 className="sign__title Title" style={{ marginBottom: 20 }}>Подписать Документ</h3>

            {!initialized
                ? (
                    <>
                        <div className="sign__description">
                            Введите ваш номер телефона
                        </div>
                        <form onSubmit={requestCode}>
                            <div className="sign__inner">
                                <PhoneInput
                                    className="sign__phone"
                                    width="available"
                                    value={phone}
                                    onChange={onPhoneChange}
                                    placeholder="Номер телефона"
                                />
                                <Button
                                    className="sign__button"
                                    type="submit"
                                    view="extra"
                                >
                                    Получить код
                                </Button>
                            </div>
                        </form>
                    </>
                )

                : <form>
                    {/*<Input width="available"*/}
                    {/*       value={code}*/}
                    {/*       onChange={setCode}*/}
                    {/*       onFocus={clearError}*/}
                    {/*       placeholder="Код из смс"*/}
                    {/*       mask={'111111'}*/}
                    {/*/>*/}
                    <div className="sign__description">
                        Для продолжения введите код отправленный на <b>{phone}</b>
                    </div>
                    <div className="sign__inner">
                        <CodeInput
                            onFocus={clearError}
                            setPin={setCode}
                            isError={isPinIncorrect && !isCodeValid}
                        />
                        {isPinIncorrect && !isCodeValid && <div className="Input_error">{ERR_INCORRECT_PIN}</div>}
                        <div className="Signin_bottom_text">
                            {minutes === 0 && seconds === 0 ? (
                                <div style={{cursor: 'pointer'}} onClick={() => resendPhone()}>Отправить еще раз</div>
                            ) : (
                                <>
                                    Отправить еще раз через <b className="Mark">{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</b>
                                </>
                            )}
                        </div>
                    </div>
                    {/*<Button*/}
                    {/*    className="sign__button"*/}
                    {/*    type="submit"*/}
                    {/*    view="extra"*/}
                    {/*>*/}
                    {/*    Подтвердить*/}
                    {/*</Button>*/}
                </form>
            }

            {error
                ? <div className="Text Text__sm Text__error" style={{ marginTop: 4}}>{error}</div>
                : null
            }
        </div>
    )
}
